import React,{useState} from "react";
import "../../assets/css/Shipping.css";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import { shipping1, shipping2 } from "../../constant";

const ShippingInfo = () => {
  const [checked, setChecked] = useState("");

  const handleSelectCategory = (id) => {
    setChecked(id);
  };
  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader page={"Shipping Information"}/>
      <div className="ShippingInfo">
        <div className="dark_black_div">
          <div className="container px-5">
            <div className="our_shipping_process text-align-last-center pt-4rem pb-5">
              <p className="text-white f-32 font-roboto-bold mb-4">
                Our Shipping Process
              </p>
              <img src={shipping2} alt="factory" className="img-one" />
              <p className="f-16 font-roboto-reg text-white mt-5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged . It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="row pb-5 ">
              <div className="col-lg-6">
                <img src={shipping1} alt="factory" className="img-two" />
              </div>
              <div className="col-lg-6">
                <p className=" font-roboto-bold f-34 text-white mt-3">
                  Shipping Rates Within The Continental U.S
                </p>
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        className="text-white font-roboto-bold  f-27"
                        scope="col"
                      >
                        Order Total
                      </th>
                      <th
                        className="text-white font-roboto-bold  f-27"
                        scope="col"
                      >
                        Standard Rate
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-pop-reg f-25 table-color p-3">
                        $0.01-$5.00
                      </td>
                      <td className="font-pop-reg f-25 table-color p-3">
                        $2.50
                      </td>
                    </tr>
                    <tr>
                      <td className="font-pop-reg f-25 table-color p-3">
                        $5.01-$29.99
                      </td>
                      <td className="font-pop-reg f-25 table-color p-3">
                        $4.95
                      </td>
                    </tr>
                    <tr>
                      <td className="font-pop-reg f-25 table-color p-3">
                        $30-$44.99
                      </td>
                      <td className="font-pop-reg f-25 table-color p-3">
                      $7.95
                      </td>
                    </tr>
                    <tr>
                      <td className="font-pop-reg f-25 table-color p-3">
                        $30-$44.99
                      </td>
                      <td className="font-pop-reg f-25 table-color p-3">
                      $7.95
                      </td>
                    </tr>
                    <tr>
                      <td className="font-pop-reg f-25 table-color p-3">
                        $30-$44.99
                      </td>
                      <td className="font-pop-reg f-25 table-color p-3">
                      $7.95
                      </td>
                    </tr>
                    <tr>
                      <td className="font-pop-reg f-25 table-color p-3">
                        $30-$44.99
                      </td>
                      <td className="font-pop-reg f-25 table-color p-3">
                      $7.95
                      </td>
                    </tr>
                    <tr>
                      <td className="font-pop-reg f-25 table-color p-3">
                        $30-$44.99
                      </td>
                      <td className="font-pop-reg f-25 table-color p-3">
                      $7.95
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default ShippingInfo;
