import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { avail } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function AddressModal(props) {
  const {
    Addaddresshandler,
    setIsOpenModal,
    isOpenModal,
    addcountry,
    setAddCountry,
    addstate,
    setAddState,
    addcity,
    setAddCity,
    addname,
    setAddName,
    addphone,
    setAddPhone,
    addzip,
    setAddZip,
    addaddress,
    setAddAddress,
    error,
    setError,
    modalloading,
    setModalLoading,
  } = props;

  return (
    <>
      {/* Address Modal Section  */}
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className="address-modal"
      >
        <ModalBody>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="closeIcon"
            onClick={() => setIsOpenModal(false)}
          />
          <div className="container">
            <div className="row user-fields">
              {/* <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">Name</p>
                <input
                  type="text"
                  placeholder="Name"
                  value={addname}
                  onChange={(e) => setAddName(e.target.value)}
                />
              </div> */}
              <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">Zip Code</p>
                <input
                  type="number"
                  placeholder="Zip Code"
                  value={addzip}
                  onChange={(e) => setAddZip(e.target.value)}
                />
              </div>
              <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">City</p>
                <input
                  type="text"
                  placeholder="City"
                  value={addcity}
                  onChange={(e) => setAddCity(e.target.value)}
                />
              </div>
              <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">Address</p>
                <input
                  type="text"
                  placeholder="Address Type"
                  value={addaddress}
                  onChange={(e) => setAddAddress(e.target.value)}
                />
              </div>
              <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">Country</p>
                <input
                  type="text"
                  placeholder="Country"
                  value={addcountry}
                  onChange={(e) => setAddCountry(e.target.value)}
                />
              </div>
              <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">State</p>
                <input
                  type="text"
                  placeholder="State"
                  value={addstate}
                  onChange={(e) => setAddState(e.target.value)}
                />
              </div>
              <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">Contact</p>
                <input
                  type="number"
                  placeholder="Contact"
                  value={addphone}
                  onChange={(e) => setAddPhone(e.target.value)}
                />
              </div>
              <div
                className="col-md-12 col-sm-12 col-12 mt-4 "
                style={{ textAlign: "center" }}
              >
                <button
                  type="button"
                  className="btn saveBtn"
                  onClick={(e) => Addaddresshandler(e)}
                  disabled={modalloading}
                >
                  {modalloading ? "Loading..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* =============== */}
    </>
  );
}

export default AddressModal;
