import { faCamera, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import SideBar from "../../components/SideBar/SideBar";
import { p1 } from "../../constant";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Addaddress,
  Adddefault,
  Deleteaddress,
  Editaddress,
  Getaddressdata,
} from "../../network/Network";
import Radio from "@mui/material/Radio";
import { pink } from "@mui/material/colors";
import swal from "sweetalert";

function AddressPage() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [addcountry, setAddCountry] = useState("");
  const [addstate, setAddState] = useState("");
  const [addaddress, setAddAddress] = useState("");
  const [addphone, setAddPhone] = useState("");
  const [addzip, setAddZip] = useState("");
  const [addname, setAddName] = useState("");
  const [addcity, setAddCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [addresing, setAddressing] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [userAddress, setUserAddress] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [postAddress, setPostAddress] = useState([]);
  const [selectedAddress, setSelectedPostAddress] = useState();
  const [editAddress, setEditAddress] = useState(false);
  const [defaultadd, setdefaultadd] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  //   Address Default Handler
  const Addressdefault = (e, id) => {
    e.preventDefault();
    Adddefault(id, Token).then((res) => {
      swal({
        text: res?.data?.message,
        timer: 2000,
        icon: "success",
      });
      setPostAddress(res?.data);
    });
  };
  //   ================

  // Add AddressHandler
  const Addaddresshandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = [];
    if (!addcountry) {
      errors.push({ addcountry: "Country is required" });
    }
    if (!addstate) {
      errors.push({ addstate: "State is required" });
    }
    if (!addaddress) {
      errors.push({ addaddress: "Address is required" });
    }
    if (!addphone) {
      errors.push({ addphone: "Phone is required" });
    }
    if (!addzip) {
      errors.push({ addzip: "Zip is required" });
    }
    // if (!addname) {
    //   errors.push({ addname: "Name is required" });
    // }
    if (!addcity) {
      errors.push({ addcity: "City is required" });
    }
    setError(errors);
    let data = {
      country: addcountry,
      state: addstate,
      address: addaddress,
      phone: addphone,
      zip: addzip,
      // contact_person_name: addname,
      city: addcity,
    };

    Addaddress(data, Token)
      .then((res) => {
        setLoading(false);
        setPostAddress(res?.data);
        setAddCountry("");
        setAddState("");
        setAddAddress("");
        setAddPhone("");
        setAddZip("");
        setAddName("");
        setAddCity("");
        swal({
          text: "Your Address has been Saved",
          timer: 2000,
          icon: "success",
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  //   ============

  // Get Address Api
  useEffect(() => {
    Getaddressdata(Token).then((res) => {
      setAddressing(res?.data?.data);
    });
  }, [postAddress]);
  //   ==========

  // Detail Addressdetail Handler
  const Deleteaddreesall = (id, index) => {
    let data = {
      address_id: id,
    };
    setSpinLoad(true);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Address!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Deleteaddress(data, Token)
          .then((res) => {
            setAddressing((preVal) => {
              return [...preVal.filter((val) => val?.id !== id)];
            });
            // setUserAddress((prev) => prev?.id != id)
            // toast.success(res?.data?.message);
            swal(res?.data?.message, {
              icon: "success",
            });
            // paymentData?.splice(index, 1);
            // setPaymentData(res?.data?.response?.data);
            setSpinLoad(false);
          })
          .catch((err) => {
            setSpinLoad(false);
          });
      } else {
        swal("Your Address is safe!");
        setSpinLoad(false);
      }
    });
  };
  //   =============

  //  AddressUpdate Handler
  const AddressUpdate = (e, item) => {
    e.preventDefault();
    setEditAddress(true);
    setSelectedPostAddress(item);
    setAddCountry(item?.country);
    setAddState(item?.state);
    setAddAddress(item?.address);
    setAddPhone(item?.phone);
    setAddZip(item?.zip);
    // setAddName(item?.addname);
    setAddCity(item?.city);
  };
  //   ================

  // EditAddress Handler
  const Editaddressall = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      country: addcountry,
      state: addstate,
      address: addaddress,
      phone: addphone,
      zip: addzip,
      // contact_person_name: addname,
      city: addcity,
      id: selectedAddress?.id,
      
    };
    
    Editaddress(data, Token)
      .then((res) => {
        // toast.success(res?.data?.message);
        swal({
          text: res?.data?.message,
          timer: 2000,
          icon: "success",
        });
        setPostAddress(res?.data);
        setEditAddress(false);
        setLoading(false);
        setAddCountry("");
        setAddState("");
        setAddAddress("");
        setAddPhone("");
        setAddZip("");
        setAddName("");
        setAddCity("");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  //   ==========

  return (
    <>
      <Header />
      <UrlHeader page={"Address"} />
      {/* Address Section  */}
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2 className="page-name">My Address</h2>
              <SideBar />
            </div>
            <div className="col-md-9">
              <div className="Profile">
                <form action="">
                  <div className="row">
                    {/* <div className="col-md-6">
                      <div className="address-input">
                        <input
                          type="text"
                          value={addname}
                          placeholder="Name"
                          onChange={(e) => setAddName(e.target.value)}
                        />
                        {error.map((errors, indexam1) => {
                          return (
                            <>
                              {errors?.addname ? (
                                <p className="error-color" key={indexam1}>
                                  {errors?.addname}
                                </p>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="address-input">
                        <input
                          type="text"
                          value={addcountry}
                          placeholder="Country"
                          onChange={(e) => setAddCountry(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="address-input">
                        <input
                          type="text"
                          value={addstate}
                          placeholder="State"
                          onChange={(e) => setAddState(e.target.value)}
                        />
                        {error.map((errors, indexam6) => {
                          return (
                            <>
                              {errors?.addstate ? (
                                <p className="error-color" key={indexam6}>
                                  {errors?.addstate}
                                </p>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="address-input">
                        <input
                          type="text"
                          value={addaddress}
                          placeholder="Address"
                          onChange={(e) => setAddAddress(e.target.value)}
                        />
                        {error.map((errors, indexam4) => {
                          return (
                            <>
                              {errors?.addaddress ? (
                                <p className="error-color" key={indexam4}>
                                  {errors?.addaddress}
                                </p>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="address-input">
                        <input
                          type="number"
                          value={addphone}
                          placeholder="Phone"
                          onChange={(e) => setAddPhone(e.target.value)}
                        />
                        {error.map((errors, indexam7) => {
                          return (
                            <>
                              {errors?.addphone ? (
                                <p className="error-color" key={indexam7}>
                                  {errors?.addphone}
                                </p>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="address-input">
                        <input
                          type="number"
                          value={addzip}
                          placeholder="Zip"
                          onChange={(e) => setAddZip(e.target.value)}
                        />
                        {error.map((errors, indexam2) => {
                          return (
                            <>
                              {errors?.addzip ? (
                                <p className="error-color" key={indexam2}>
                                  {errors?.addzip}
                                </p>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="address-input">
                        <input
                          type="text"
                          value={addcity}
                          placeholder="City"
                          onChange={(e) => setAddCity(e.target.value)}
                        />
                        {error.map((errors, indexam3) => {
                          return (
                            <>
                              {errors?.addcity ? (
                                <p className="error-color" key={indexam3}>
                                  {errors?.addcity}
                                </p>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    {/* <div className="col-md-6"></div> */}
                    <div className="col-md-12">
                      <div className="add-address-button">
                        {selectedAddress ? (
                          <button
                            className="btn addAddress"
                            onClick={(e) => Editaddressall(e)}
                            disabled={loading}
                          >
                            {loading ? "Loading..." : "Update Address"}
                          </button>
                        ) : (
                          <button
                            className="btn addAddress"
                            onClick={(e) => Addaddresshandler(e)}
                            disabled={loading}
                          >
                            {loading ? "Loading..." : "Save Address"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {addresing?.map((item, index) => {
                return (
                  <div
                    className="radio_add_box p-3 pt-4 d-flex mb-3"
                    key={index}
                  >
                    <div className="row width_full ps-3">
                      {/* <div className="col-lg-12">
                        <div className="all-check-out-record">
                          <p className=" font-roboto-medium f-16">Name : </p>
                          <span className="gry-clr font-roboto-regular">
                            {item?.contact_person_name}
                          </span>
                        </div>
                      </div> */}
                      <div className="col-lg-12">
                        <div className="all-check-out-record">
                          <p className=" font-roboto-medium f-16">Country : </p>
                          <span className="gry-clr font-roboto-regular">
                            {item?.country}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="all-check-out-record">
                          <p className=" font-roboto-medium f-16"> State : </p>
                          <span className="gry-clr font-roboto-regular">
                            {item?.state}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="all-check-out-record">
                          <p className=" font-roboto-medium f-16">
                            {" "}
                            Address :{" "}
                          </p>
                          <span className="gry-clr font-roboto-regular">
                            {item?.address}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="all-check-out-record">
                          <p className=" font-roboto-medium f-16"> Zip : </p>
                          <span className="gry-clr font-roboto-regular">
                            {item?.zip}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="all-check-out-record">
                          <p className=" font-roboto-medium f-16"> City : </p>
                          <span className="gry-clr font-roboto-regular">
                            {item?.city}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="all-check-out-record">
                          <p className=" font-roboto-medium f-16"> Phone : </p>
                          <span className="gry-clr font-roboto-regular">
                            {item?.phone}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-lg-6 text-right">
                        <div className="div-address-btn">
                          <button
                            className={`${
                              item?.is_default == "1" ? "btn2" : "btn default"
                            }`}
                            disabled={item?.default == "1" ? true : false}
                            onClick={(e) => Addressdefault(e, item?.id)}
                          >
                            Default
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="pre-delete">
                      <i
                        onClick={(e) => Deleteaddreesall(item?.id, index)}
                        className="fa fa-trash-o"
                        disabled={loading}
                        aria-hidden="true"
                      ></i>
                    </div>

                    <div className="pre-edit">
                      <i
                        onClick={(e)=>AddressUpdate(e, item)}
                        className="fa fa-pencil-square"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* =============== */}

      <NewsLetter />
      <Footer />
    </>
  );
}

export default AddressPage;
