import React, { useEffect, useState } from "react";
import Avail from "../../components/Avail/Avail";
import Banner from "../../components/Banner/Banner";
import PopularCard from "../../components/Card/PopularCard";
import ProductCard from "../../components/Card/ProductCard";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import TopSlider from "../../components/Slider/TopSlider";
import Testimonial from "../../components/Testimonial/Testimonial";
import {
  avail,
  popular1,
  popular2,
  popular3,
  popular4,
  popular5,
  popular6,
  arrival1,
  arrival2,
  arrival3,
  subSlider1,
  subSlider2,
  subSlider3,
  p1,
  blog1,
  blog2,
  blog3,
  pre,
  next,
  next2,
  prev2,
} from "../../constant";
import Slider from "react-slick";
import PercentOffAdds from "../../components/Adds/PercentOffAdds";
import FollowUs from "../../components/FollowUs/FollowUs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Aos from "aos";
import {
  Aboutusget,
  GetAllCategories,
  GetAllProducts,
  GetNews,
  Popularget,
  getReview,
  getTestimonial,
} from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import { useSelector } from "react-redux";
import moment from "moment";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <img src={next} alt=" " className="image-fluid image-nextPrev " />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={pre} alt=" " className="image-fluid image-nextPrev " />
    </div>
  );
}

function SampleNextArrow2(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={next2} alt=" " className="image-fluid image-nextPrev " />
    </div>
  );
}

function SamplePrevArrow2(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={prev2} alt=" " className="image-fluid image-nextPrev " />
    </div>
  );
}

function Home() {
  const Navigate = useNavigate();
  const BlogsReduxData = useSelector((state) => state.AuthReducer.blogs);
  const HotDeals = useSelector((state) => state.AuthReducer.hotDeals);
  const Popular = useSelector((state) => state.AuthReducer.popular);

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
  };
  const subSlider = {
    arrow: true,
    dots: true,
    infinite: true,
    // speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const navigate = useNavigate();

  const navigateToProducts = () => {
    // 👇️ navigate to /contacts
    navigate("/all-product-page");
  };
  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [spinLoad, setSpinLoad] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState("");
  const [testi, setTesti] = useState([]);
  const [aboutget, setAboutGet] = useState();
  const [popular, setGetPopular] = useState();
  const [getcategories, setGetCategorries] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const CategoriesRedux = useSelector(
    (state) => state.AuthReducer.categoriesData
  );
  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  // Get All Prodcts Api
  useEffect(() => {
    setSpinLoad(true);
    GetAllProducts(currentPage)
      .then((res) => {
        setProductsData(res?.data?.data?.products?.data);
        const total = res?.data?.data?.products?.total;
        const limit = res?.data?.data?.products?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);
  // ===================

  // Testimonial Api
  useEffect(() => {
    getTestimonial()
      .then((res) => {
        setTesti(res?.data?.data?.data);
      })
      .catch((err) => {});
  }, []);
  // ======================

  // Get About Api
  useEffect(() => {
    Aboutusget().then((res) => {
      setAboutGet(res?.data?.data);
    });
  }, []);
  // ============

  // Get Popular api
  useEffect(() => {
    Popularget()
      .then((res) => {
        setGetPopular(res?.data?.data);
      })
      .catch((err) => {});
  }, []);
  // ===============

  // Get All Categories Api
  useEffect(() => {
    GetAllCategories().then((res) => {
      setGetCategorries(res?.data?.data);
    });
  }, []);
  // ================

  // Get News
  useEffect(() => {
    setSpinLoad(true);
    GetNews()
      .then((res) => {
        setNewsData(res?.data?.data?.blogs);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);
  //   =================

  return (
    <>
      <Header setChecked={setChecked} />
      <Banner />
      {/* Categories Slider Section  */}
      <div className="top-subSlider">
        <div className="container">
          <div className="sub-header-text">
            <p className="ptext">Product Categories</p>
            <p>
              <Link to="/all-product-page" className="plink">
                View All
              </Link>
            </p>
          </div>
          <Slider {...subSlider}>
            {CategoriesRedux?.map((item, indextop) => {
              return (
                <TopSlider
                  item={item}
                  index={indextop}
                  handleSelectCategory={handleSelectCategory}
                />
              );
            })}
          </Slider>
        </div>
      </div>
      {/* =============== */}

      {/* New Arrival Section  */}
      <div className="container">
        <div className="newArival-container">
          <h2 className="title">
            New <span>Arrivals</span>
          </h2>
          <div>
            <p className="para mt-5">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div className="product-card">
            <div className="row">
              {spinLoad ? (
                <>
                  <div className="loader-container">
                    <SpinnerCircular size="80px" color="#d60012" />
                  </div>
                </>
              ) : (
                <>
                  {productsData?.map((item, index) => {
                    return (
                      <>
                        {index < 3 ? (
                          <>
                            <div className="col-md-4">
                              <ProductCard item={item} index={index} />
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Link to="/all-product-page" className="seeAll">
              See all
            </Link>
          </div>
        </div>
      </div>
      {/* ============== */}
      <PercentOffAdds />

      {/* Popular Section  */}
      <div className="popular-section">
        <div className="container">
          <div className="popular-header">
            <h2 className="title">
              Popular <span>Items</span>
            </h2>
            <p className="para">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div>
            <div className="row">
              {popular?.map((item, index) => {
                return (
                  <>
                    {index < 3 ? (
                      <>
                        <PopularCard item={item} index={index} />
                      </>
                    ) : null}
                  </>
                );
              })}
            </div>
            <div className="button-container">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => Navigate("/all-product-page")}
              >
                View More
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ================= */}

      {/* Testimonial Section  */}
      <div className="testimonial-bg">
        <div className="container">
          <Slider {...settings}>
            {testi?.map((item, index) => {
              return <Testimonial item={item} index={index} />;
            })}
          </Slider>
        </div>
      </div>
      {/* =================== */}

      <Avail page={"home"} aboutget={aboutget} setAboutGet={setAboutGet} />

      {/* Blog Section  */}
      <div className="blog-page home-blog-page">
        <div className="container">
          <h2 className="title">
            {/* News <span>&</span> Events */}
            Blog
          </h2>
          <div className="row">
            {newsData?.map((item, indexhbl) => {
              return (
                <>
                  {indexhbl < 3 ? (
                    <>
                      <div className="col-md-4 mb-4" key={indexhbl}>
                        <div
                          className="card h-100"
                          data-aos="zoom-in-up"
                          data-aos-duration="3000"
                        >
                          <div className="image-box">
                            <img
                              src={item?.image_url}
                              alt=""
                              className="image-fluid image-width"
                            />
                          </div>
                          <div className="header">
                            <p className="mr-2">Blogs</p>
                            {/* <p>| {item?.created_at}</p> */}
                            <p>
                              |{" "}
                              {moment(item?.created_at).format(
                                "MMMM Do YYYY , h:mm a"
                              )}
                            </p>
                          </div>
                          <p className="desc">{item?.title}</p>
                          {/* <Link to={`/blogdetail/${item?.id}`} className="readMore"> */}
                          <Link
                            to={`/blog-detail/${item?.slug}`}
                            state={{ data: item }}
                            className="readMore"
                          >
                            Read more{" "}
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              className="arrow-icon"
                            />
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              );
            })}
          </div>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => Navigate("/blog")}
            >
              View All
            </button>
          </div>
        </div>
      </div>
      {/* ============== */}
      <FollowUs />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default Home;
