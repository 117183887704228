import React, { useEffect, useState } from "react";
import ProductCard from "../../components/Card/ProductCard";
import MuiCategories from "../../components/Categories/MuiCategories";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { arrival1, arrival2, arrival3 } from "../../constant";
import UrlHeader from "../../components/Header/UrlHeader";
import PercentOffAdds from "../../components/Adds/PercentOffAdds";
import PriceFilter from "../../components/Price Range/PriceFilter";
import { GetAllProducts, GetProductsFilter, PostPriceFilter } from "../../network/Network";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";

function ProductPage() {
  const Parameters = useParams();
  const CategoriesRedux = useSelector(
    (state) => state.AuthReducer.categoriesData
  );

  const [highToLow, setHighToLow] = useState(null);
  const [spinLoad, setSpinLoad] = useState(false);
  const [startPrice, setStartPrice] = useState("");
  const [endPrice, setEndPrice] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [productsData, setProductsData] = useState([]);
  const [checked, setChecked] = useState("");
  // let CurrentUrl = window.location.url("/");
  let currentUrl = window.location.href.split("/");


  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  const getProductsRequest = (id) => {
    setSpinLoad(true);
    GetProductsFilter(currentPage, id, highToLow)
      .then((res) => {
        setProductsData(res?.data?.data?.data);
        // setProductImages(res?.data?.data?.data);
        const total = res?.data?.data?.total;
        const limit = res?.data?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        // let cateData = JSON.parse(productsData?.category_ids);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  };

  useEffect(() => {
    getProductsRequest(checked);
  }, [currentPage, checked, highToLow]);


  useEffect(() => {
    let filterCategoryId = CategoriesRedux?.filter(
      (val) => 
      ((val?.slug === Parameters?.Id)? true : (val?.childes.filter((innerVal)=> innerVal?.slug === Parameters?.Id )))    
      // val?.slug === Parameters?.Id
      );
    if (filterCategoryId?.length > 0)
      getProductsRequest(filterCategoryId[0]?.id);
  }, [Parameters?.Id]);

   // Price Filter
   const PriceFilterHandler = (e) => {
    e.preventDefault();
    setSpinLoad(true);
    let filterCategoryId = CategoriesRedux?.filter(
      (val) => val?.slug === Parameters?.Id
    );
    let data = {
      start: startPrice,
      end: endPrice,
      id: checked ? checked : filterCategoryId[0]?.id,
    };
    PostPriceFilter(currentPage, data)
      .then((res) => {
        setProductsData(res?.data?.data?.products?.data);
        const total = res?.data?.data?.products?.total;
        const limit = res?.data?.data?.products?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  };


  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader page={"Products"}/>
      <div className="container">
        <div className="row category-margin">
          <div className="col-md-3">
            <div className="categories-container">
              <div className="header">
                <p className="title">Categories</p>
              </div>
              {CategoriesRedux?.map((item, index) => {
                return (
                  <MuiCategories 
                    item={item} 
                    index={index} 
                    handleSelectCategory={handleSelectCategory}
                  />
                )
              })}
            </div>
            <PriceFilter
              startPrice={startPrice}
              setStartPrice={setStartPrice}
              endPrice={endPrice}
              setEndPrice={setEndPrice}
              PriceFilterHandler={PriceFilterHandler}
            />
          </div>
          <div className="col-md-9">
            <div>
              <div className="header-result">
                <div>
                  <p className="result-text">Showing 1–16 of 17 results</p>
                </div>
                <div>
                  <select 
                    name="filter" 
                    id="filter" 
                    className="sizeSelect"
                    onChange={(e) => {
                      setHighToLow(e.target.value);
                    }}
                    >
                    <option value=""> Default Sorting</option>
                    <option value="high_to_low">Hign To Low</option>
                    <option value="low_to_high">Low To High</option>
                  </select>
                </div>
              </div>

              <div className="row">
              {spinLoad ? (
                  <>
                    <div className="loader-container">
                      <SpinnerCircular size="80px" color="#d60012" />
                    </div>
                  </>
                  ) : (
                    <>
                       {productsData.length > 0 ? (
                         <>
                          {productsData?.map((item, index) => {
                            return (
                              <>
                                <div className="col-md-4 product-card">
                                  <ProductCard item={item} index={index} />
                                </div>
                              </>
                            );
                            })}
                         </>
                       ) : (
                         <>
                           <p>Product not found</p>
                         </>
                       ) }
                    </>
                )
              } 
              </div>

              <div className="pagination-container mt-5">
                <ReactPaginate
                  // renderOnZeroPageCount={null}
                  previousLabel="<<"
                  nextLabel=">>"
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      <PercentOffAdds />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default ProductPage;
