import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { avail } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function SizeModal(props) {
  const { setIsOpenModal, isOpenModal, productdetails, setProductDetails } =
    props;
  return (
    <>
      {/* Size Section  */}
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className="sizeChart-modal"
      >
        <ModalBody>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="closeIcon"
            onClick={() => setIsOpenModal(false)}
          />
          <div className="row">
            <div className="col-md-3">
              <div className="image-box">
                <img src={avail} alt="" className="image-fluid image-width" />
              </div>
            </div>
            <div className="col-md-9">
              <table className="table table-striped table-dark">
                <thead>
                  <tr>
                    <th scope="col">Size</th>
                    <th scope="col">Length(cm)</th>
                    <th scope="col">Waist(cm)</th>
                    <th scope="col">Chest(cm)</th>
                    <th scope="col">Arm(cm)</th>
                  </tr>
                </thead>
                <tbody>
                  {productdetails?.size_of_products.map((item, indexch) => {
                    return (
                      <tr>
                        <th scope="row" style={{ backgroundColor: "#707070 " }}>
                          {item?.size_name}
                        </th>
                        <td>{item?.length}</td>
                        <td>{item?.waist}</td>
                        <td>{item?.chest}</td>
                        <td>{item?.arm}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* =========== */}
    </>
  );
}

export default SizeModal;
