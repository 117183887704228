import React, { useEffect, useState } from "react";
import "../../assets/css/Faq.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../components/Footer/Footer";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import { GetFaqs, PostQuestions } from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import { toast } from "react-toastify";
import swal from "sweetalert";

const Faq = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [checked, setChecked] = useState("");
  const [error, setError] = useState([]);
  const handleSelectCategory = (id) => {
    setChecked(id);
  };
  // Get Faq APi
  useEffect(() => {
    setSpinLoad(true);
    GetFaqs()
      .then((res) => {
        setFaqData(res?.data?.data?.faqs);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);
  // ===================

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Send Message Function
  const SendMessage = (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = [];
    if (!fullName) {
      errors.push({ fullName: "Full Name is required" });
    }
    if (!email) {
      errors.push({ email: "Email is required" });
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      errors.push({ email: "Email is Invalid" });
    }
    if (!question) {
      errors.push({ question: "Question is required" });
    } else if (question.length < 20) {
      errors.push({ question: "The message must be at least 20 characters" });
    }
    setError(errors);
    let data = {
      full_name: fullName,
      email: email,
      message: question,
    };
    PostQuestions(data)
      .then((res) => {
        setLoading(false);
        swal({
          title: "Message",
          text: "Successfully Send",
          timer: 2000,
          icon: "success",
        });
        setFullName("");
        setEmail("");
        setQuestion("");
        setError("");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ===========
  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"FAQ's"} />
      {/* All Faq Section  */}
      <div className="pos-rel Faqs">
        <div className="dark_black_div">
          <div className="row">
            {spinLoad ? (
              <>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#d60012" />
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-12 text-center">
                  <div className="accordian_faqs p-3">
                    {faqData?.map((item, index) => {
                      return (
                        <Accordion key={index} className="mb-3 border-0">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <p className="f-17 font-roboto-medium mb-0 text-start faq-question">
                              {item?.question}
                            </p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <p className="bordr-top-lightgry pt-3 text-start f-15 font-roboto-regular faq-answer">
                              {item?.answer}
                            </p>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* Send Message Section  */}
        <div className="red_div p-5 p-res-faqs">
          <div className="container">
            <div className="row mt-5">
              <div className="offset-lg-1 col-lg-4 text-start">
                <p className="f-50 font-roboto-medium text-white line-height-normal">
                  Can’t find the answer to your question?
                </p>
                <p className="font-sintony f-16 text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
              <div className=" col-lg-6  text-start">
                <input
                  type="text"
                  placeholder="Your full name"
                  className="form-control inp-faq-page font-roboto-regular"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                {error.map((errors, indexfaq2) => {
                  return (
                    <>
                      {errors?.fullName ? (
                        <p className="error-color-white" key={indexfaq2}>
                          {errors?.fullName}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <input
                  type="text"
                  placeholder="Your e-mail address"
                  className="form-control inp-faq-page font-roboto-regular mt-2-3"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error.map((errors, indexfaq2) => {
                  return (
                    <>
                      {errors?.email ? (
                        <p className="error-color-white" key={indexfaq2}>
                          {errors?.email}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <textarea
                  rows={6}
                  placeholder="Drop your question here"
                  className="form-control inp-faq-page font-roboto-regular mt-2-3"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                ></textarea>
                {error.map((errors, indexfaq3) => {
                  return (
                    <>
                      {errors?.question ? (
                        <p className="error-color-white" key={indexfaq3}>
                          {errors?.question}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <div className="border-white  mt-4">
                  <button
                    className="view_more btn font-17 font-roboto-regular"
                    onClick={(e) => SendMessage(e)}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Send Message"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ============== */}
      </div>
      {/* ============= */}
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Faq;
