import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import { pink } from "@mui/material/colors";
import "../../assets/css/Checkout.css";

function AddressCard(props) {
  const {
    item,
    index,
    handleChange,
    selectedValue,
    setSelectedValue,
    Addressdefault,
  } = props;
  return (
    <>
	{/* Address Section  */}
      <div className="radio_add_box p-3 pt-4 d-flex mb-3" key={index}>
        <div className="row width_full ps-3">
          <div className="col-lg-12">
            <div className="all-check-out-record">
              <p className="font-roboto-medium f-16">Country : </p>
              <span className="gry-clr font-roboto-regular">
                {item?.country}
              </span>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="all-check-out-record">
              <p className=" font-roboto-medium f-16"> State : </p>
              <span className="gry-clr font-roboto-regular">
                {item?.state}
              </span>{" "}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="all-check-out-record">
              <p className=" font-roboto-medium f-16"> Address : </p>
              <span className="gry-clr font-roboto-regular">
                {item?.address}
              </span>{" "}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="all-check-out-record">
              <p className=" font-roboto-medium f-16"> Phone : </p>
              <span className="gry-clr font-roboto-regular">
                {item?.phone}
              </span>{" "}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="all-check-out-record">
              <p className=" font-roboto-medium f-16"> City : </p>
              <span className="gry-clr font-roboto-regular">
                {item?.city}
              </span>{" "}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="all-check-out-record">
              <p className=" font-roboto-medium f-16"> Zip : </p>
              <span className="gry-clr font-roboto-regular">
                {item?.zip}
              </span>{" "}
            </div>
          </div>
          <div className="col-lg-12 text-right">
            <div className="default-btn">
              <button
                className={`${
                  item?.is_default == "1" ? "btn2" : "btn default"
                }`}
                disabled={item?.default == "1" ? true : false}
                onClick={(e) => Addressdefault(e, item?.id)}
              >
                Default
              </button>
            </div>
          </div>
        </div>
      </div>
	  {/* ============== */}
    </>
  );
}

export default AddressCard;
