import React, { useEffect, useState } from "react";
import "../../assets/css/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMagnifyingGlass,
  faHeart,
  faCartShopping,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Update_logo, cartimg, headlogo } from "../../constant";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ramdomImage } from "../../constant/ConstantFunction";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import { SocialLinks, siteinfo } from "../../network/Network";

function Header(props) {
  const { setChecked, checked } = props;
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);

  const CategoriesRedux = useSelector(
    (state) => state.AuthReducer.categoriesData
  );
  const SocialMediaRedux = useSelector(
    (state) => state.AuthReducer.socialMedia
  );
  const CartReduxData = useSelector((state) => state.CartReducer.cartData);
  let currentUrl = window.location.href.split("/");
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [collapse, setCollapse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [sitesetting, setSiteSetting] = useState();
  const [getsociallinks, setGetSocialLinks] = useState([]);

  // Login Handler
  const LogoutHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      let x = await dispatch(logout());
      // Navigate("/Signin");
      window.location.href = "/sign-in";
    }, 200);
  };
  // =========

  // Get Sitesetting Api
  useEffect(() => {
    siteinfo().then((res) => {
      setSiteSetting(res?.data?.data);
    });
  }, []);
  // ===============

  // Social Media Get Api
  useEffect(() => {
    SocialLinks().then((res) => {
      setGetSocialLinks(res?.data?.data?.social);
    });
  }, []);
  // ===============
  return (
    <>
      <div>
        {/* Nav top section   */}
        <section className="nav-top">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6">
                <div className="nav-para">
                  <p>
                    <FontAwesomeIcon className="forcolor" icon={faPhone} /> :
                    <a>{sitesetting?.company_phone?.value}</a>
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-6">
                <div className="nav-ul social-ul">
                  <ul>
                    {getsociallinks?.map((item, indexsm1) => {
                      return (
                        // <li key={indexsm1}>
                        //   <a href={item?.link}>
                        //     <FontAwesomeIcon
                        //       className="forcolor"
                        //       icon={item?.icon}

                        //     />
                        //   </a>
                        // </li>
                        <li key={indexsm1}>
                          <a href={`${item?.link}`} target="_blank">
                            <i className={item?.icon} aria-hidden="true"></i>
                          </a>
                        </li>
                      );
                    })}
                    {/* <li>
                      <a href="">
                        <FontAwesomeIcon
                          className="forcolor"
                          icon={faFacebookSquare}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FontAwesomeIcon
                          className="forcolor"
                          icon={faYoutube}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FontAwesomeIcon
                          className="forcolor"
                          icon={faTwitter}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FontAwesomeIcon
                          className="forcolor"
                          icon={faInstagram}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FontAwesomeIcon
                          className="forcolor"
                          icon={faLinkedinIn}
                        />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ================== */}

        {/* Header Nav Bottom Section  */}
        <section className="header nav-bottom">
          <div className="container">
            <nav className="navbar-dark navbar-expand-md navigation-clean-search ">
              <div className="row">
                <div className="col-md-2">
                  <Link className="navbar-brand navbar-left" to="/">
                    <div className="head-logo">
                      <img
                        alt="header-logo"
                        src={sitesetting?.company_web_logo?.web_logo}
                      />
                    </div>
                  </Link>
                </div>

                <div className="col-md-8">
                  <div
                    className={
                      "collapse navbar-collapse right-box for-justiy-center-nav" +
                      (collapse && "show")
                    }
                    id="navbarNavDropdown"
                  >
                    <ul className="nav navbar-nav ">
                      <li className="nav-item" role="presentation">
                        <Link to="/" className="nav-link">
                          Home
                        </Link>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          to="/all-product-page"
                          className="nav-link dropdown-toggle custom-toggle"
                          id="navbarDropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Products
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <li className="border-bottomStyle">
                            <Link
                              to="/hot-deals"
                              className="dropdown-item header-customItems"
                            >
                              Hot Deals
                            </Link>
                          </li>
                          {CategoriesRedux?.map((item, index) => {
                            return (
                              <>
                                <li className="dropdown-submenu  border-bottomStyle">
                                  <Link
                                    to={`/products/${item?.slug}`}
                                    className="dropdown-item dropdown-toggle header-customItems "
                                    key={item?.id}
                                  >
                                    <span onClick={() => setChecked(item?.id)}>
                                      {item?.name}
                                    </span>
                                  </Link>
                                  <ul className="dropdown-menu">
                                    {item?.childes?.map((childItem, index) => {
                                      return (
                                        <li
                                          className="border-bottomStyle"
                                          key={childItem?.id}
                                        >
                                          <Link
                                            to={`/products/${item?.slug}/${childItem?.slug}`}
                                            className="dropdown-item header-customItems"
                                          >
                                            <span
                                              onClick={() =>
                                                setChecked(childItem?.id)
                                              }
                                            >
                                              {childItem?.name}
                                            </span>
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </li>
                      <li className="nav-item" role="presentation">
                        <Link to="/about-us" className="nav-link">
                          About
                        </Link>
                      </li>
                      <li className="nav-item" role="presentation">
                        <Link to="/faqs" className="nav-link">
                          Faq's
                        </Link>
                      </li>
                      <li className="nav-item" role="presentation">
                        <Link to="/blog" className="nav-link">
                          Blogs
                        </Link>
                      </li>
                      <li className="nav-item" role="presentation">
                        <Link to="/contact-us" className="nav-link">
                          Contact
                        </Link>
                      </li>
                      {isLogin ? (
                        <li className="nav-item" role="presentation">
                          <Link to="/gift-card" className="nav-link">
                            Gift Card
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>

                <div className="col-md-2">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="nav-item p-2" role="presentation">
                      <Link to="/cart">
                        <div className="cart-num">{CartReduxData?.length}</div>
                        <FontAwesomeIcon
                          icon={faCartShopping}
                          className="social-color icon-style"
                        />
                      </Link>
                    </li>

                    {Token ? (
                      <li>
                        <div className="heart-whishlist-icon">
                          <Link to="/wishlist">
                            <i className="fa fa-heart-o" aria-hidden="true"></i>
                          </Link>
                        </div>
                      </li>
                    ) : (
                      <li>
                        <li className="login-btn">
                          <a
                            onClick={() => toast.error("Please Login")}
                            className={`${
                              currentUrl?.[3] === "wishlist" ? "active" : null
                            }`}
                          >
                            <i className="fa fa-heart-o" aria-hidden="true"></i>
                          </a>
                        </li>
                      </li>
                    )}

                    {isLogin ? (
                      <>
                        <li
                          className="nav-item p-2 ij profile-header"
                          role="presentation"
                        >
                          <a
                            // to="/auth/profile"
                            className="nav-link dropdown-toggle flex-style"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <div
                              className="profile"
                              onClick={() => Navigate("/profile")}
                            >
                              <img
                                src={`${ramdomImage(
                                  `${userData?.f_name}${userData?.l_name}`
                                )}`}
                                className="image-width"
                                style={{ borderRadius: "50%" }}
                              />
                            </div>
                          </a>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link to="/profile" className="dropdown-item">
                              <p
                                className="ml-2 header-role  m-0"
                                // onClick={(e) => LogoutHandler(e)}
                                style={{ cursor: "pointer" }}
                              >
                                My Profile
                              </p>
                            </Link>

                            <a className="dropdown-item">
                              <p
                                className="ml-2 header-role m-0"
                                onClick={(e) => LogoutHandler(e)}
                                style={{ cursor: "pointer" }}
                              >
                                Logout
                              </p>
                            </a>
                          </div>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="nav-item p-2" role="presentation">
                          <Link to="/sign-in">
                            <FontAwesomeIcon
                              icon={faUser}
                              className="social-color icon-style"
                            />
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </section>
        {/* ============ */}
      </div>
      <div className="canvas_btn " onClick={() => setView(true)}>
        <Link className="navbar-brand navbar-left" to="/">
          <div className="head-mobile-nav-logo">
            <img
              alt="header-logo"
              src={sitesetting?.company_web_logo?.web_logo}
            />
          </div>
        </Link>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>
      {/* <!-- Mobile Header Start Here --> */}
      <div className={view ? "mobile_header show" : "mobile_header "}>
        <div className="cancel" onClick={() => setView(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#000"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
        <ul className="mobile_menus">
          <ul className="nav navbar-nav ">
            <li className="nav-item" role="presentation">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                to="/all-product-page"
                className="nav-link dropdown-toggle custom-toggle"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Products
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li className="border-bottomStyle">
                  <Link
                    to="/hot-deals"
                    className="dropdown-item header-customItems"
                  >
                    Hot Deals
                  </Link>
                </li>
                {CategoriesRedux?.map((item, index) => {
                  return (
                    <>
                      <li className="dropdown-submenu  border-bottomStyle">
                        <Link
                          to={`/products/${item?.slug}`}
                          className="dropdown-item dropdown-toggle header-customItems "
                          key={item?.id}
                        >
                          <span onClick={() => setChecked(item?.id)}>
                            {item?.name}
                          </span>
                        </Link>
                        <ul className="dropdown-menu">
                          {item?.childes?.map((childItem, index) => {
                            return (
                              <li
                                className="border-bottomStyle"
                                key={childItem?.id}
                              >
                                <Link
                                  to={`/products/${item?.slug}/${childItem?.slug}`}
                                  className="dropdown-item header-customItems"
                                >
                                  <span
                                    onClick={() => setChecked(childItem?.id)}
                                  >
                                    {childItem?.name}
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </>
                  );
                })}
              </ul>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="/about-us" className="nav-link">
                About
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="/faqs" className="nav-link">
                FAQ's
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="/blog" className="nav-link">
                Blogs
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="/contact-us" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </ul>
      </div>
      {/* <!-- Mobile Header End Here --> */}
      <Outlet />
    </>
  );
}

export default Header;
