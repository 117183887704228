import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import AllReviews from "../../components/Review/AllReviews";
import ReviewBox from "../../components/Review/ReviewBox";
import { blogdetail, post1, post2, post3 } from "../../constant";
import { Blogdetailget, GetNews } from "../../network/Network";

function BlogDetails() {
  const location = useLocation();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [checked, setChecked] = useState("");
  const [getblog, setGetBlog] = useState([]);
  const [getblogdetail, setGetBlogDetail] = useState("");
  const handleSelectCategory = (id) => {
    setChecked(id);
  };
  let currentUrl = window.location.href.split("/");
  // Blogs Get Api
  useEffect(() => {
    GetNews()
      .then((res) => {
        setGetBlog(res?.data?.data?.blogs);
      })
      .catch((err) => {});
  }, []);
  // ============

  // Blogdetail Get Api
  useEffect(() => {
    Blogdetailget(currentUrl[4])
      .then((res) => {
        setGetBlogDetail(res?.data?.data?.blog);
      })
      .catch((err) => {});
  }, [currentUrl[4]]);
  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader />
      {/* Begin of Blog Detail Section  */}
      <div className="blog-detail">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="image-box blog-detail-img">
                <img
                  src={getblogdetail?.image_url}
                  alt=""
                  className="image-fluid image-width"
                />
              </div>
              <div>
                <p className="heading mt-4">{getblogdetail?.title}</p>
                <p
                  className="desc"
                  dangerouslySetInnerHTML={{ __html: getblogdetail?.content }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="recentPost">
                <div className="header">
                  <p className="title">Recent Post</p>
                </div>

                {getblog?.map((item, indexbgd) => {
                  return (
                    <>
                      {indexbgd < 3 ? (
                        <Link to={`/blog-detail/${item?.slug}`}>
                          <div className="postcard" key={indexbgd}>
                            <div className="image-box blog-recent-img">
                              <img
                                src={item?.image_url}
                                alt=""
                                className="image-fluid image-width"
                              />
                            </div>
                            <div>
                              <p className="desc">{item?.title}</p>
                            </div>
                          </div>
                        </Link>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            {/* <AllReviews /> */}
            <hr />
            {/* <ReviewBox /> */}
          </div>
        </div>
      </div>
      {/* ============= */}
      <NewsLetter />
      <Footer />
    </>
  );
}

export default BlogDetails;
