import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import '../../assets/css/thankyou.css'

function Thankyou() {
  return (
    <>
      <Header />
      {/* <!-- Thank You Sec Css Start Here --> */}
      <section className="tahnk-you">
        <div className="container">
          <div className="row">
     
            <div className="col-lg-12">
              <div className="thank-box">
                <div className="heading">
                  <h3>Thank You</h3>
                </div>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Nostrum repellendus voluptates amet, provident nam,
                  consequuntur corrupti, voluptatibus explicabo unde impedit
                  nemo modi? Est placeat ipsam provident? Autem laborum
                  laboriosam doloremque!
                </p>
                <div className="button-group">
                  <Link to="/all-product-page" className="kigi-btn">
                  Continue Shopping
                   </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Thank You Sec Css End Here --> */}
      <Footer />
    </>
  );
}

export default Thankyou;
