import React from "react";
import Slider from "react-slick";
import { banner1, banner2, banner3 } from "../../constant";
import { Link, useNavigate } from "react-router-dom";

function Banner() {

  const navigate = useNavigate();
  const navigateToProducts = () => {
    // 👇️ navigate to /contacts
    navigate('/all-product-page');
  };
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    fade: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: false,
    prevArrow: false,
  };
  const BannerData = [
    {
      id: 1,
      img: banner1,
      title: "Clothes",
      sub: "that Match Your",
      inner: "Personality",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
      id: 2,
      img: banner2,
      title: "Clothes",
      sub: "that Match Your",
      inner: "Personality",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
      id: 3,
      img: banner3,
      title: "Clothes",
      sub: "that Match Your",
      inner: "Personality",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
  ];

  
  return (
    <>
      <div className="Banner-container">
        <Slider {...settings}>
          {BannerData?.map((item, index) => {
            return (
              <div className="banner-slider" key={index}>
                <img src={item?.img} className="image-fluid" alt="" />
                <div className="contentDv">
                  <div className="content-inner">
                    <h2 className="title">{item?.title}</h2>
                    <h2 className="sub-title">{item?.sub}</h2>
                    <h2 className="personality">{item?.inner}</h2>
                    <p className="para">
                      {item?.desc} <br />
                     {/* <Link to="/blog"> <span>Read More</span></Link> */}
                      {/* <Link>Read More</Link> */}
                    </p>
                    <div className="mt-3 home-banner-dv">
                      <button onClick={navigateToProducts} type="button" className="btn btn-primary">
                        Shop Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div className="banner-slider">
            <img src={banner1} className="image-fluid" alt="" />
          </div>
          <div className="banner-slider">
            <img src={banner1} className="image-fluid" alt="" />
          </div> */}
        </Slider>
      </div>
    </>
  );
}

export default Banner;
