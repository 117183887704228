import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Update_logo, footerB1, footerB2, footerlogo } from "../../constant";
import { CompanyInfo, GetNews, siteinfo } from "../../network/Network";
import { Blogs, CompanyInfoStore } from "../../redux/actions/AuthActions";
import moment from "moment/moment";

const Footer = () => {
  const dispatch = useDispatch();
  const [newsData, setNewsData] = useState([]);
  const [sitesetting, setSiteSetting] = useState();
  const [spinLoad, setSpinLoad] = useState(false);

  // Get Recent Blog Api
  useEffect(() => {
    setSpinLoad(true);
    GetNews()
      .then((res) => {
        setNewsData(res?.data?.data?.blogs);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);
  // ==============

  // Site Setting Api
  useEffect(() => {
    siteinfo().then((res) => {
      setSiteSetting(res?.data?.data);
    });
  }, []);
  // ============
  return (
    <>
      {/* Footer Section  */}
      <footer className="footer cover-banner">
        <div className="container">
          <div className="inner-container">
            <div className="row pb-3">
              <div className="col-md-4 widgets2 border-right">
                <div className="footer-logo">
                  <img
                    src={sitesetting?.company_footer_logo?.web_footer_logo}
                    className="image-fluid"
                    alt=""
                  />
                </div>

                <h4 className="pb-3 first-col">
                  {sitesetting?.company_footer_info?.value}
                </h4>
              </div>
              <div className="col-md-4 widgets2 border-right">
                <h4 className="pb-3 text-white mainText">Quick links</h4>
                <div>
                  <ul>
                    <li>
                      <Link to="/shipping-information">
                        {" "}
                        Shipping Information{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy"> Privacy Policy </Link>
                    </li>
                    <li>
                      <Link to="/refund-policy"> Refund Policy </Link>
                    </li>
                    <li>
                      <Link to="/cookie-policy"> Cookies Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions"> Terms and conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 widgets2">
                <h4
                  className="pb-3 text-white mainText"
                  style={{ textAlign: "center" }}
                >
                  Recent Blog
                </h4>
                <div className="blog">
                  {newsData?.map((item, index) => {
                    return (
                      <>
                        {index < 2 ? (
                          <Link to={`/blog-detail/${item?.slug}`}>
                            <div className="blog-container mb-4" key={index}>
                              <div className="footer-profile">
                                <img
                                  src={item?.image_url}
                                  className="image-fluid image-width"
                                  alt="footer-blog-img"
                                />
                              </div>
                              <div>
                                <h4 className="title">{item?.title}</h4>
                                <p
                                  className="title m-0"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.content,
                                  }}
                                />
                                <p className="date">
                                  {moment(item?.created_at).format(
                                    " DD MMM YYYY"
                                  )}
                                </p>
                              </div>
                            </div>
                          </Link>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* =========== */}

      {/* Copyright Section  */}
      <div className="container-fluid footer-copyright ">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-12">
              <p
                className="para copyright"
                dangerouslySetInnerHTML={{
                  __html: sitesetting?.company_copyright_text?.value,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* ============ */}
    </>
  );
};

export default Footer;
