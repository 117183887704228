import React, { useState } from "react";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import "../../assets/css/Forgot.css";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { SetResetPassword } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";

// import "../Pages/ForgotPasswordConfirm.css";

const NewPassword = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState("");
  const [error, setError] = useState([]);
  const value = queryString.parse(location.search);
  const token = value.token;

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  // Reset Password Handler 
  const Resetpasswordhandler = async (e) => {
    e.preventDefault();
    const errors = [];
    if (!password) {
      errors.push({ password: "password is required" });
    } else if (password.length < 8) {
      errors.push({ password: "enter only 8 characters for password" });
    }

    if (!confirmPassword) {
      errors.push({ confirmPassword: "password is required" });
    } else if (confirmPassword != password) {
      errors.push({
        confirmPassword:
          "Your passwords do not match. Please make sure they match exactly.",
      });
    }
    setError(errors);

    let data = {
      reset_token: token,
      password: password,
      password_confirmation: confirmPassword,
    };
    setTimeout(async () => {
      setLoading(true);
      let send = await dispatch(SetResetPassword(data, Navigate));
      setLoading(false);
    });
  };
  // ================
  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"New Password"} />
      {/* Forgot Password Section  */}
      <div className="ForgotPasswordConfirm">
        <div>
          <div className="dark_black_div">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h3 className="mt-4rem font-roboto-bold f-31">
                    Create New Password
                  </h3>
                  <p className="f-16 font-roboto-bold mt-3">
                    Create Your New Password
                  </p>
                  <div className="form-signup px-3">
                    <input
                      type="password"
                      placeholder="New Password"
                      className="form-control p-3 signup-fld f-15 font-roboto"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {error.map((errors, indexns9) => {
                      return (
                        <>
                          {errors?.password ? (
                            <p className="error-color" key={indexns9}>
                              {errors?.password}
                            </p>
                          ) : null}
                        </>
                      );
                    })}{" "}
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      className="form-control mt-3 p-3 signup-fld f-15 font-roboto"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {error.map((errors, indexns10) => {
                      return (
                        <>
                          {errors?.confirmPassword ? (
                            <p className="error-color" key={indexns10}>
                              {errors?.confirmPassword}
                            </p>
                          ) : null}
                        </>
                      );
                    })}{" "}
                    <div className="d-flex">
                      <button
                        className="btn btn-danger brbd-no-rad bg-red btn font-18 form-control font-roboto mt-4"
                        onClick={(e) => Resetpasswordhandler(e)}
                        disabled={loading}
                      >
                        <span>{loading ? "Loading..." : "Submit"}</span>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== */}
    </>
  );
};

export default NewPassword;
