import React from "react";
import norecord from "../../src/assets/images/norecord.png";

const NoRecord = () => {
  return (
    // Norecord Section
    <div className="notFound-container">
      {/* <p>No Record Found</p> */}
      <img src={norecord} alt="not found" className="image-fluid" />
    </div>
    // ============
  );
};

export default NoRecord;
