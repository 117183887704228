import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../assets/css/Contact.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { PostContactUs } from "../../network/Network";
import swal from "sweetalert";

const Contact = () => {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState("");
  const [error, setError] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  // Contact Handler
  const ContactUsHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = [];
    if (!name) {
      errors.push({ name: "First Name is required" });
    }
    if (!email) {
      errors.push({ email: "Email is required" });
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      errors.push({ email: "Email is Invalid" });
    }

    if (!phone) {
      errors.push({ phone: "phone is required" });
    } else if (phone.length < 11) {
      errors.push({ phone: "Phone number must be 11 characters or less" });
    }

    if (!subject) {
      errors.push({ subject: "address is required" });
    }

    if (!message) {
      errors.push({ message: "address is required" });
    }

    setError(errors);
    let data = {
      name: name,
      email: email,
      mobile_number: phone,
      subject: subject,
      message: message,
    };
    PostContactUs(data, Token)
      .then((res) => {
        setLoading(false);
        swal({
          title: "Contact Us",
          text: "Thanks For Contacting Us",
          timer: 2000,
          icon: "success",
        });
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ===================
  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"Contact Us"} />
      {/* Contact Us Section  */}
      <div className="pos-rel Contact">
        <div className="dark_black_div">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h3 className="mt-4rem mb-280 font-roboto-bold f-32">
                Let's Create Progress Together
              </h3>
            </div>
          </div>
        </div>

        <div className="red_div p-res-contact-form">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="form_box">
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {error.map((errors, indexc1) => {
                        return (
                          <>
                            {errors?.name ? (
                              <p className="error-color" key={indexc1}>
                                {errors?.name}
                              </p>
                            ) : null}
                          </>
                        );
                      })}
                    </div>
                    {/* <div className="col-lg-6">
                  <input
                        placeholder="Last Name"
                        className="form-control f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={lastName}
                        onChange={(e)=>setLastName(e.target.value)}
                      ></input>
                  </div> */}
                    <div className="col-lg-6">
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control media-mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {error.map((errors, indexc2) => {
                        return (
                          <>
                            {errors?.email ? (
                              <p className="error-color" key={indexc2}>
                                {errors?.email}
                              </p>
                            ) : null}
                          </>
                        );
                      })}
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="number"
                        placeholder="Phone"
                        className="form-control media-mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      {error.map((errors, indexc3) => {
                        return (
                          <>
                            {errors?.phone ? (
                              <p className="error-color" key={indexc3}>
                                {errors?.phone}
                              </p>
                            ) : null}
                          </>
                        );
                      })}
                    </div>
                    <div className="col-lg-12">
                      <input
                        type="text"
                        placeholder="Subject"
                        className="form-control media-mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      {error.map((errors, indexc4) => {
                        return (
                          <>
                            {errors?.subject ? (
                              <p className="error-color" key={indexc4}>
                                {errors?.subject}
                              </p>
                            ) : null}
                          </>
                        );
                      })}
                    </div>
                    {/* <div className="col-lg-6">
                      <input
                        placeholder="First Name"
                        className="form-control f-14 p-3 rounded-0 font-roboto-reg res-pad"
                      ></input>
                      <input
                        placeholder="Last Name"
                        className="form-control f-14 mt-4 p-3 rounded-0 font-roboto-reg res-pad"
                      ></input>
                    </div>

                    <div className="col-lg-6">
                      <input
                        placeholder="Email"
                        className="form-control media-mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad"
                      ></input>
                        
                      <select className="form-select mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad">
                        <option disabled>Select a subject</option>
                        <option>Select a subject</option>
                        <option>Select a subject</option>
                        <option>Select a subject</option>
                        <option>Select a subject</option>
                      </select>
                    </div> */}

                    <div className="col-lg-12">
                      <textarea
                        className="form-control mt-4 txt-area p-3 rounded-0 f-14 font-roboto-reg res-pad"
                        rows={8}
                        placeholder="Drop us a message here…"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      {error.map((errors, indexc5) => {
                        return (
                          <>
                            {errors?.message ? (
                              <p className="error-color" key={indexc5}>
                                {errors?.message}
                              </p>
                            ) : null}
                          </>
                        );
                      })}
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-btn">
                        <button
                          type="button"
                          className="btn  btn-primary view_more font-15"
                          onClick={(e) => ContactUsHandler(e)}
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Send"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============== */}
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Contact;
