import {
	AUTH,
	SIGNUP,
	LOGIN,
	CATEGORIES,
	FAQ,
	CONTACTUS,
	COMPANY_INFORMATION,
	CUSTOMER,
	UPDATE_PROFILE,
	PRODUCTS,
	LATEST,
	STATUS,
	WISHLIST,
	REMOVE,
	ORDER,
	LIST,
	QUESTION_STORE,
	GETOURTEAM,
	STORE_USER_ADDRESS,
	// ---
	HOTDEALS,
	RELATED_PRODUCTS,
	TRADING,
	REVIEWS,
	NEWSLETTER,
	ADD,
	PLACE,
	TRACK,
	REMOVE_ALL,
	CUSTOMER_ID,
	SOCIAL_MEDIA,
	LINKS,
	COUPON,
	APPLY,
	CODE,
	PRODUCT_ID,
	SEARCH,
	NAME,
	SUBMIT,
	BRANDS,
	PAGE,
	PRICE,
	START,
	END,
	BANNERS,
	BANNER_TYPE,
	ADS,
	BLOGS,
	FORGOT_PASSWORD,
	COUNTRY,
	RESET_PASSWORD,
	CHANGE_PASSWORD,
	TOP_PRODUCTS,
	GET_PAGE,
	TYPE,
	TERMS,
	V,
	COOKIE,
	REFUND,
	PRIVACY,
	GIFTCARD,
	SEND,
	VERIFY,
	ADDRESS,
	UPDATE,
	ABOUTUS,
	SITEINFORMATION,
	DETAILS,
	POPULAR,
	BLOG,
	TESTIMONIALS,
	// end
	// FROGOT_PASSWORD,
} from "../network/Endpoint";

import {
	doGet,
	doPost,
	doPatch,
	doDelete,
	doPostProfilePictureUpload,
} from "./Config";

export const postSignUp = (data) => {
	return doPost(`${AUTH}${SIGNUP}`, data);
};

export const postSignIn = (data) => {
	return doPost(`${AUTH}${LOGIN}`, { ...data });
};

export const GetAllCategories = () => {
	return doGet(`${CATEGORIES}`);
};

export const GetFaqs = () => {
	return doGet(`${FAQ}`);
};

export const PostContactUs = (data, token) => {
	return doPost(`${CONTACTUS}`, data, token);
};

export const PostNewsLetter = (data) => {
	return doPost(`${NEWSLETTER}`, data);
};

export const CompanyInfo = () => {
	return doGet(`${COMPANY_INFORMATION}`);
};

export const UpdateProfile = (data, token) => {
	return doPostProfilePictureUpload(
		`${CUSTOMER}${UPDATE_PROFILE}`,
		data,
		token,
	);
};

export const GetAllProducts = (currentPage, data) => {
	return doGet(
		`${PRODUCTS}${LATEST}${STATUS}=${data}${`&`}${`page`}=${currentPage}`,
	);
};

export const GetWishList = (currentPage, token) => {
	return doGet(`${CUSTOMER}${WISHLIST}${PAGE}=${currentPage}`, token);
};

export const AddWishList = (data, token) => {
	return doPost(`${CUSTOMER}${WISHLIST}${ADD}`, data, token);
};

export const DeleteWishList = (id, token) => {
	return doDelete(`${CUSTOMER}${WISHLIST}${REMOVE}${PRODUCT_ID}=${id}`, token);
};

export const ClearWishList = (id, token) => {
	return doDelete(
		`${CUSTOMER}${WISHLIST}${REMOVE_ALL}${CUSTOMER_ID}=${id}`,
		token,
	);
};

export const GetAllOrders = (current_page, token) => {
	return doGet(`${CUSTOMER}${ORDER}${LIST}${PAGE}=${current_page}`, token);
};


export const GetNews = () => {
	return doGet(`${BLOGS}`);
};

// ------ end

export const GetHotDeals = (currentPage, data) => {
	return doGet(
		`${PRODUCTS}${HOTDEALS}${STATUS}=${data}${`&`}${`page`}=${currentPage}`,
	);
};

export const GetSimilarProducts = (id) => {
	return doGet(`${PRODUCTS}${RELATED_PRODUCTS}/${id}`);
};

export const GetTradingProducts = (currentPage) => {
	return doGet(`${PRODUCTS}${TRADING}${PAGE}=${currentPage}`);
};

export const GetProductsFilter = (currentPage, id, data) => {
	return doGet(
		`${CATEGORIES}${PRODUCTS}/${id}${STATUS}=${data}${`&`}${`page`}=${currentPage}`,
	);
};

export const SelectedProductReviews = (id, currentPage) => {
	return doGet(`${PRODUCTS}${REVIEWS}/${id}${PAGE}=${currentPage}`);
};

export const GetHotProductFilter = (currentPage, data, id) => {
	return doGet(
		`${CATEGORIES}${PRODUCTS}${HOTDEALS}/${id}${STATUS}=${data}${`&`}${`page`}=${currentPage}`,
	);
};

export const PlaceOrder = (data, token) => {
	return doPost(`${CUSTOMER}${ORDER}${PLACE}`, data, token);
};

export const OrderTracking = (id, token) => {
	return doGet(`${TRACK}/${id}`, token);
};

export const SocialLinks = () => {
	return doGet(`${SOCIAL_MEDIA}${LINKS}`);
};

export const ApplyCoupons = (data) => {
	return doGet(`${COUPON}${APPLY}${CODE}=${data}`);
};

export const SearchProducts = (data) => {
	return doGet(`${PRODUCTS}${SEARCH}${NAME}=${data}`);
};

export const PostProductRating = (data, token) => {
	return doPost(`${PRODUCTS}${REVIEWS}${SUBMIT}`, data, token);
};

export const GetAllBrands = () => {
	return doGet(`${BRANDS}`);
};

export const GetFilterBrands = (currentPage, id) => {
	return doGet(`${BRANDS}${PRODUCTS}/${id}${PAGE}=${currentPage}`);
};

export const PostPriceFilter = (currentPage, data) => {
	return doGet(
		`${PRODUCTS}${SEARCH}${PRICE}${START}=${data?.start}&${END}=${
			data?.end
		}${`&category`}=${data?.id}`,
	);
};

export const GetHomeBanner = (data) => {
	return doGet(`${BANNERS}${BANNER_TYPE}=${data?.type}`);
};

export const GetAds = () => {
	return doGet(`${ADS}`);
};

export const forgotPasswordApi = (data) => {
	return doPost(`${AUTH}${FORGOT_PASSWORD}`, data);
};

export const forgotPasswordOtp = (data) => {
	return doPost(`${AUTH}${RESET_PASSWORD}`, data);
};

export const ResetPasswordApi = (data) => {
  return doPost(`${AUTH}${RESET_PASSWORD}`, data);
};

export const GetCountryApi = () => {
	return doGet(`${COUNTRY}`);
};

export const PostChangePassword = (data, token) => {
	return doPost(`${CUSTOMER}${CHANGE_PASSWORD}`, data, token);
};

export const TopProducts = () => {
	return doGet(`${TOP_PRODUCTS}`);
};

export const PageData = (data) => {
	return doGet(`${GET_PAGE}${TYPE}=${data}`);
};

export const SocialMediaLinks = () => {
	return doGet(`${SOCIAL_MEDIA}${LINKS}`);
};

export const PostQuestions = (data) => {
	return doPost(`${QUESTION_STORE}`, data);
};

export const OurTeam = () => {
	return doGet(`${GETOURTEAM}`);
};

export const PostAddress = (data, Token) => {
	return doPost(`${CUSTOMER}${STORE_USER_ADDRESS}`, data, Token);
};

export const getTerms = () => {
	return doGet(`${TERMS}`);
};

export const getCookie = () => {
	return doGet(`${COOKIE}`);
};
export const getRefund = () => {
	return doGet(`${REFUND}`);
};
export const getPolicy = () => {
	return doGet(`${PRIVACY}`);
};

export const getReview = () => {
	return doGet(`${REVIEWS}`);
};
export const getTestimonial =()=>{
	return doGet(`${TESTIMONIALS}`)
}
 
export const Postgift = (data) => {
	return doPost(`${GIFTCARD}${SEND}`, data);
};

export const Postyougftcard = (data) => {
	return doPost(`${GIFTCARD}${VERIFY}`, data);
};

export const Addaddress = (data, token) => {
	return doPost(`${CUSTOMER}${ADDRESS}${ADD}`, data, token);
};

export const Getaddressdata = (token) => {
	return doGet(`${CUSTOMER}${ADDRESS}${LIST}`, token);
};

export const Deleteaddress = (data, token) => {
	return doPost(`${CUSTOMER}${ADDRESS}`, data, token);
};

export const Editaddress = (data, token) => {
	return doPost(`${CUSTOMER}${ADDRESS}${UPDATE}`, data, token);
};

export const Aboutusget = () => {
	return doGet(`${ABOUTUS}`);
};

export const Adddefault = (id, token) => {
	return doGet(`${CUSTOMER}${ADDRESS}/${id}`, token);
};

export const siteinfo = () => {
	return doGet(`${SITEINFORMATION}`);
};

export const productdetailsapi = (slug) => {
	return doGet(`${PRODUCTS}${DETAILS}/${slug}`);
};

export const Popularget = () => {
	return doGet(`${PRODUCTS}${POPULAR}`);
};

export const Blogdetailget = (slug) => {
	return doGet(`${BLOG}/${slug}`);
};
// ---END --
