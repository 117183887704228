import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  summary: {
    backgroundColor: "transparent !important",
    color: "#fffff !important",
    // boxShadow: "unset !important",
  },
  mainContainer: {
    backgroundColor: "transparent !important",
    boxShadow: "unset !important",
    borderBottom: "1px solid #70707030",
  },
  expand_icon: {
    color: "white",
  },
}));

export default function MuiCategories(props) {
  const { item, index,  handleSelectCategory } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const classes = useStyles();

  console.log("yaaa", item)
  
  return (
    <div>
      <Accordion
        expanded={expanded === item?.name}
        onChange={handleChange(item?.name)}
        className={classes.mainContainer}
        key={index}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expand_icon} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          // className="custom-accord"
          className={classes.summary}
        >
          {/* <Typography
            sx={{
              width: "18%",
              flexShrink: 0,
              textAlign: "center",
              color: "#fff !important",
            }}
          ></Typography> */}
          <Typography sx={{ color: "#ffff" , cursor: "pointer"}}>
            <p className="cat-title"
              // onClick={() => handleSelectCategory(item?.id)}
            >
              <Link 
                 to={`/products/${item?.slug}`}
                 style={{color: "#fff"}}
                 onClick={() => handleSelectCategory(item?.id)}
              >
                 {item?.name}
              </Link>
            </p>
            </Typography>
        </AccordionSummary>
        {item?.childes?.map((childItem, index) => {
          return (
            <AccordionDetails
              sx={{
                borderBottom: "0px solid #70707030",
                borderTop: "1px solid #70707030",
                padding: "12px 16px 12px",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  // flexShrink: 0,
                  textAlign: "initial",
                  marginLeft: "8%",
                  padding: 'unset'
                  
                }}
              >
                <Typography
                  sx={{ color: "#fff", marginLeft: "8px" , cursor: "pointer"}}
                  onClick={() => handleSelectCategory(childItem?.id)}
                >
                   <Link 
                      to={`/products/${item?.slug}/${childItem?.slug}`}
                      style={{color: "#fff"}}
                    >
                      {childItem?.name}
                    </Link>
                </Typography>
                  {/* {childItem?.name} */}
              </Typography>
            </AccordionDetails>
          );
        })}
      </Accordion>
    </div>
  );
}
