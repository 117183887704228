import React,{useState} from "react";
import "../../assets/css/Policy.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { getCookie } from "../../network/Network";
import { useEffect } from "react";

const Cookie = () => {
  const [checked, setChecked] = useState("");
  const [cookie ,setCookie] = useState("null")
  
  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  // Cookie Policy Api
  useEffect(() => {
		getCookie()
			.then((res) => {
				setCookie(res?.data?.data?.data);
			})
			.catch((err) => {
			});
	}, []);
	// ==========================================
  return (
    <>
      <Header 
         setChecked={setChecked} 
      />
      <UrlHeader  page={"Cookie Policy "}/>
      <div className="pos-rel POL">
        <div className="dark_black_div">
          <div className="container">
            <div className="row mt-4rem">
              <div className="col-lg-12 mb-3 mt-3">
              <p
									className="f-16 font-roboto  text-start"
									dangerouslySetInnerHTML={{ __html: cookie?.value }}
								/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Cookie;
