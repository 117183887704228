import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { avail } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function ChangePasswordModal(props) {
  const {
    setIsOpenModalPass,
    isOpenModalPass,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    changePassword,
    error,
    setError,
    modalBtn,
  } = props;
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [nvalues, setNValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [cvalues, setCValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPasswords = () => {
    setNValues({ ...nvalues, showPassword: !nvalues.showPassword });
  };
  const handleClickShowPasswordss = () => {
    setCValues({ ...cvalues, showPassword: !cvalues.showPassword });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  return (
    <>
      {/* Change Password Section  */}
      <Modal
        isOpen={isOpenModalPass}
        toggle={() => {
          setIsOpenModalPass(false);
        }}
        className="address-modal"
      >
        <ModalBody>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="closeIcon"
            onClick={() => setIsOpenModalPass(false)}
          />
          <div className="container">
            <div className="row user-fields">
              <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">Current Password</p>
                <input
                  type={values.showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                {error.map((errors, indexs2) => {
                  return (
                    <>
                      {errors?.currentPassword ? (
                        <p className="error-color" key={indexs2}>
                          {errors?.currentPassword}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <div className="profile-icon-password">
                  <i
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    className="fa fa-eye"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">New Password</p>
                <input
                  type={nvalues.showPassword ? "text" : "password"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {error.map((errors, indexs2) => {
                  return (
                    <>
                      {errors?.newPassword ? (
                        <p className="error-color" key={indexs2}>
                          {errors?.newPassword}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <div className="profile-icon-password">
                  <i
                    onClick={handleClickShowPasswords}
                    onMouseDown={handleMouseDownPassword}
                    className="fa fa-eye"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-12 mt-4">
                <p className="input-label">Confirm Password</p>
                <input
                  type={cvalues.showPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {error.map((errors, indexs2) => {
                  return (
                    <>
                      {errors?.confirmPassword ? (
                        <p className="error-color" key={indexs2}>
                          {errors?.confirmPassword}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <div className="profile-icon-password">
                  <i
                    onClick={handleClickShowPasswordss}
                    onMouseDown={handleMouseDownPassword}
                    className="fa fa-eye"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>

              <div
                className="col-md-12 col-sm-12 col-12 mt-4 "
                style={{ textAlign: "center" }}
              >
                <button
                  type="button"
                  className="btn saveBtn"
                  onClick={(e) => changePassword(e)}
                  disabled={modalBtn}
                >
                  {modalBtn ? "Loading..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* ========== */}
    </>
  );
}

export default ChangePasswordModal;
