import React, { useState } from "react";
import { Update_logo, add1, logo } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { PostNewsLetter } from "../../network/Network";
import swal from "sweetalert";

function NewsLetter() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  // News Handler
  const NewsLetterHandler = (e) => {
    e.preventDefault();
    const errors = [];
    let data = {
      email: email,
    };
    PostNewsLetter(data)
      .then((res) => {
        setLoading(false);
        swal({
          title: "Newsletter",
          text: "Join Newsletter Successfully!!",
          icon: "success",
          timer: 2000,
        });
        setEmail("");
        setError("");
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.status === 403) {
          swal({
            text: err?.response?.data?.errors[0]?.message,
            timer: 2000,
            icon: "error",
          });
          return;
        }
      });
  };
  // =============

  return (
    <>
      {/* News letter Section  */}
      <div className="NewsLetter-container">
        <div className="container">
          <div>
            <div className="logo">
              <img src={Update_logo} className="image-fluid" alt="" />
            </div>
            <p className="mt-2 news-container-heading">
              Subscribe to Our Newsletter to know Latest News & Updates
            </p>
            <div className="input-container mt-4">
              <input
                type="text"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FontAwesomeIcon
                className="footer-icon arrow-icon"
                icon={faArrowRight}
                onClick={(e) => NewsLetterHandler(e)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* ============= */}
    </>
  );
}

export default NewsLetter;
