import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/Login.css";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import { login } from "../../redux/actions/AuthActions";
import { useEffect } from "react";

const Login = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [checked, setChecked] = useState("");
  const [error, setError] = useState([]);
  const [remeber, setRemeber] = useState(false);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  const handleremeberme = (event) => {
    setRemeber(!remeber);
  };

  // Remeber Handler
  useEffect(() => {
    const remeberMe = localStorage.getItem("remeberMe") === "true";
    const email = remeberMe ? localStorage.getItem("email") : "";
    const password = remeberMe ? localStorage.getItem("password") : "";
    setEmail(email);
    setPassword(password);
    setRemeber(remeberMe);
  }, []);
  // =============

  // Sign Handler Section
  const SignInHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = [];

    if (!email) {
      errors.push({ email: "Email is required" });
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      errors.push({ email: "Email is Invalid" });
    } else {
      localStorage.setItem("remeberMe", remeber);
      localStorage.setItem("email", remeber ? email : "");
      localStorage.setItem("password", remeber ? password : "");
    }

    if (!password) {
      errors.push({ password: "password is required" });
    } else if (password.length < 8) {
      errors.push({ password: "enter only 8 characters for password" });
    }

    setError(errors);

    let data = {
      email: email,
      password: password,
    };

    setTimeout(async () => {
      setLoading(false);
      let x = await dispatch(login(data));
      if (x) {
        Navigate("/");
      }
    }, 600);
  };
  // ===============

  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"Sign In"} />
      {/* Login Section  */}
      <div className="login">
        <div className="dark_black_div">
          <div className="container">
            <div className="row pb-5">
              <div className="col-lg-12 text-center">
                <h3 className="mt-4rem font-roboto-bold f-31">
                  Sign in to your Account
                </h3>
                <p className="f-16 font-roboto-light pad-res-lft-rght-login">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore.
                </p>
                <div className="container">
                  <div className="row">
                    <div className="offset-lg-1 col-lg-5 pe-5 text-start pe-res-login">
                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <p className="font-mon-semibold f-15 mt-5">Email</p>
                          </div>

                          <div className="col-md-12">
                            <input
                              type="text"
                              placeholder="Enter Your Email"
                              id="email"
                              className="form-control p-3 f-13 font-mon-medium rounded-0 signup-fld"
                              value={email}
                              required
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {error.map((errors, indexl1) => {
                              return (
                                <>
                                  {errors?.email ? (
                                    <p className="error-color" key={indexl1}>
                                      {errors?.email}
                                    </p>
                                  ) : null}
                                </>
                              );
                            })}
                          </div>

                          <div className="col-md-12">
                            <div className="confirm-password-icon">
                              <p className="font-mon-semibold f-15 mt-3">
                                Password
                              </p>
                              <input
                                type={values.showPassword ? "text" : "password"}
                                placeholder="Enter Your Password"
                                id="password"
                                className="form-control p-3 f-13 font-mon-medium rounded-0 signup-fld"
                                value={password}
                                required
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              {error.map((errors, indexl2) => {
                                return (
                                  <>
                                    {errors?.password ? (
                                      <p className="error-color" key={indexl2}>
                                        {errors?.password}
                                      </p>
                                    ) : null}
                                  </>
                                );
                              })}
                              <div className="icon-password">
                                <i
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  className="fa fa-eye"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="remmber-and-forget-dv">
                              <div className="remeber-me-dv">
                                <input
                                  type="checkbox"
                                  id="Remember"
                                  className="form-check-input"
                                  value={remeber}
                                  checked={remeber}
                                  onChange={(e) => handleremeberme(e)}
                                />
                                <label for="Remember">Remember Me</label>
                              </div>

                              <div className="forget-password-dv">
                                <Link
                                  to="/forgot"
                                  className="font-roboto-light f-15 forg-color mt-5"
                                >
                                  Forgotten your password?
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="d-flex">
                        <div className="border-red mt-4">
                          <button
                            className="view_more btn font-17 font-roboto-medium"
                            type="submit"
                            onClick={(e) => SignInHandler(e)}
                            disabled={loading}
                          >
                            {loading ? "Loading..." : "Sign in"}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 med-ps-5 text-start">
                      <Link
                        to="/sign-up"
                        className="font-roboto-bold f-22 mt-5 dont-text"
                      >
                        Don't Have an Acount?
                      </Link>
                      <p className="f-16  font-roboto-light desc-right">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore.
                      </p>
                      <div className="d-flex">
                        <div className="border-red mt-4">
                          <button
                            className="view_more btn font-17 font-roboto-medium"
                            onClick={() => Navigate("/sign-up")}
                          >
                            Create an Account
                          </button>
                          {/*<button className="view_more btn font-17 mt-3 font-roboto-medium">*/}
                          {/*  Create an Account*/}
                          {/*</button>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============ */}
    </>
  );
};

export default Login;
