import React from "react";
import { add1 } from "../../constant";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

function PercentOffAdds() {
  const Navigate = useNavigate()
  const Ads = useSelector((state) => state.CartReducer.ads);
  return (
    <>
    {Ads?.map((item,index)=>{
      return(
        <>
          {
            item?.title === "50 off" ? (
              <>
                <div className="AddsBanner-container"
                  data-aos="flip-up"
                  data-aos-duration="3000"
                  key={index}
                >
                <div className="inner-container">
                  <h4>{item?.sub_title}</h4>
                  <div className="button-container">
                    <div>
                      <button type="button" className="btn btn-primary"
                      onClick={()=>Navigate("/all-product-page")}
                      >
                        Shop Now
                      </button>
                    </div>
                    <div>
                      <button type="button" className="btn btn-primary"
                        onClick={()=>Navigate("/contact-us")}
                      >
                        Contact Us
                      </button>
                    </div>
                  </div>
                </div>
              <img src={item?.image_url} className="image-fluid image-width" alt="" />
            </div>
              </>
            ) : (null) 
          }
        </>
      )
    })}
  
    </>
  );
}

export default PercentOffAdds;
