import React, { useState, useEffect } from "react";
import "../../assets/css/ProductDetails.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import PercentOffAdds from "../../components/Adds/PercentOffAdds";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import ProductImagesSlider from "../../components/ProductDetails/ProductImagesSlider";
import ProductDescriptionBox from "../../components/ProductDetails/ProductDescriptionBox";
import AllReviews from "../../components/Review/AllReviews";
import ReviewBox from "../../components/Review/ReviewBox";
import SizeModal from "../../components/Modal/SizeModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Heart from "react-heart";
import {
  AddWishList,
  GetWishList,
  PostProductRating,
  SelectedProductReviews,
  productdetailsapi,
} from "../../network/Network";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  AddToCart,
  DecrementProductQuantity,
  IncrementProductQuantity,
  ProductAllReviews,
} from "../../redux/actions/CartActions";
import { WishListData } from "../../redux/actions/AuthActions";
import Slider from "react-slick";
import { ImageUrl } from "../../network/ApiUrl";
import no_image_product_details from "../../assets/images/no_image_product_details.209a9e96d77320e8aee3.webp";
import swal from "sweetalert";

function ProductDetails() {
  const location = useLocation();
  const Navigate = useNavigate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const WishListRedux = useSelector((state) => state.AuthReducer.wishlistData);
  const ReviewsDataRedux = useSelector((state) => state.CartReducer.reviewData);
  const allStates = useSelector((state) => state.CartReducer.cartData);
  const ParamData = location?.state?.data;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [active, setActive] = useState(false);
  let currentUrl = window.location.href.split("/");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [color, setColor] = useState("");
  const [wishLoading, setWishLoading] = useState(false);
  const [checked, setChecked] = useState("");
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [productReview, setProductReview] = useState([]);
  const [heartDisable, setHeartDisable] = useState(false);
  const [sizeData, setSizeData] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [productdetails, setProductDetails] = useState(null);
  const [proload, setProLoad] = useState(false);
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRating = (rate) => {
    if (rate === 20) {
      setRating(1);
    } else if (rate === 40) {
      setRating(2);
    } else if (rate === 60) {
      setRating(3);
    } else if (rate === 80) {
      setRating(4);
    } else {
      setRating(5);
    }
  };
  // const handleRating = (rate) => {
  //   setRating(rate);
  // };

  // Rating Handler
  const SubmitRating = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!rating || !comment) {
      swal({
        title: " Oops!",
        text: "Please Enter Your Review",
        icon: "error",
      });
      setLoading(false);
      return;
    }
    let data = {
      product_id: productdetails?.id,
      comment: comment,
      rating: rating,
    };
    PostProductRating(data, Token)
      .then((res) => {
        setLoading(false);
        dispatch(ProductAllReviews(res?.data?.data));
        swal({
          text: "Please Enter Your Review",
          icon: "success",
        });
        setRating("");
        setComment("");
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          swal({
            title: " Oops!",
            text: "Please Login First",
            icon: "error",
          });
        }
        setRating("");
        setComment("");
      });
  };
  // ===========

  // == Product Reviews ==
  useEffect(() => {
    SelectedProductReviews(productdetails?.id, currentPage)
      .then((res) => {
        SelectedProductReviews(res?.data?.data?.data);
        // dispatch(ProductAllReviews(res?.data?.data?.data));
        const total = res?.data?.data?.total;
        const limit = res?.data?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {});
  }, [ProductAllReviews, currentPage]);
  // ================

  // Wish list Hander
  const AddWishListHandler = () => {
    // e.preventDefault();
    setWishLoading(true);
    let data = {
      product_id: productdetails?.id,
    };
    AddWishList(data, Token)
      .then((res) => {
        setWishLoading(false);
        setActive(!active);
        swal({
          title: "Wish List!",
          text: "Product Add to your Wishlist!",
          icon: "success",
        });
      })
      .catch((err) => {
        setWishLoading(false);
        swal({
          text: err?.response?.data?.message,
          icon: "warning",
        });
      });
  };
  // ==============

  // Cart Handler
  const AddToCartHandler = (item) => {
    let filterSize;
    if (item?.choice_options?.length > 0) {
      filterSize = item?.choice_options.filter((val) => val?.title === "Size");
      if (filterSize?.length > 0) {
        if (filterSize[0]?.options?.length > 0) {
          if (!sizeData) {
            swal({
              title: " Oops!",
              text: "Please select size",
              icon: "error",
            });
            return;
          }
        }
      }
    }
    if (item?.color_name?.length > 0) {
      if (!color) {
        swal({
          title: " Oops!",
          text: "Please select Color",
          icon: "error",
        });
        return;
      }
    }
    let Make_variation = `${color}-${sizeData}`;
    let filterVariation = item?.variation?.filter(
      (val) => val?.type === Make_variation
    );
    if (filterVariation?.length > 0) {
      let data = {
        id: item?.id,
        price: filterVariation[0]?.price,
        quantity: quantity,
        color: color ? color : null,
        size: sizeData,
        productitem: item,
      };
      if (quantity > filterVariation[0]?.qty) {
        swal({
          title: " Oops!",
          text: "Selected quantity is not available..",
          icon: "error",
        });
        return;
      } else {
        dispatch(AddToCart(data));
        swal({
          title: "Success!",
          text: "Successfully added..",
          icon: "success",
        });
      }
    }

    // let checkItemAlreadyExist = allStates.filter((val) => val?.id === item?.id);
    // if (checkItemAlreadyExist.length > 0) {
    // 	swal("Item Already Exist in Cart");
    // } else {
    // 	// let colorData = JSON.parse(ParamData?.colors);
    // 	let data = {
    // 		id: item?.id,
    // 		price: item?.unit_price,
    // 		quantity: quantity,
    // 		color: color ? color : null,
    // 		size: sizeData,
    // 		productitem: item,
    // 	};
    // 	dispatch(AddToCart(data));
    // }
  };
  // =============

  // Get WishList
  useEffect(() => {
    GetWishList(currentPage, Token)
      .then((res) => {
        dispatch(WishListData(res?.data?.data?.wishlist?.data));
        const total = res?.data?.data?.wishlist?.total;
        const limit = res?.data?.data?.wishlist?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {});
  }, [currentPage]);
  // ============
  const Increment = (id) => {
    setQuantity(quantity + 1);
  };
  const Decrement = (id) => {
    setQuantity(quantity - 1);
  };

  // Product details api
  useEffect(() => {
    setProLoad(true);
    productdetailsapi(currentUrl[4])
      .then((res) => {
        setProductDetails(res?.data?.data);
      })
      .catch((err) => {
        setProLoad(false);
      });
  }, [currentUrl[4]]);
  // ============

  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"Product Details"} />
      {/* Product Detail Section  */}
      <div className="product-details">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="product-images">
                {productdetails?.product_image_url[0] ? (
                  <>
                    <div className="single-image">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider1) => setNav1(slider1)}
                        arrows={false}
                      >
                        {productdetails?.product_image_url?.map(
                          (item, index) => {
                            return (
                              <figure
                                key={index}
                                className="productDetail-slider"
                              >
                                <img src={item} className="img-fluid" />
                              </figure>
                            );
                          }
                        )}
                      </Slider>
                    </div>
                    <div className="slider-images">
                      <Slider
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}
                        slidesToShow={
                          productdetails?.product_image_url?.length > 0 ||
                          productdetails?.product_image_url?.length < 3
                            ? productdetails?.product_image_url?.length
                            : 3
                        }
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={false}
                      >
                        {productdetails?.product_image_url?.map(
                          (item, index) => {
                            return (
                              <figure key={index}>
                                <img src={item} className="img-fluid" />
                              </figure>
                            );
                          }
                        )}
                      </Slider>
                    </div>
                  </>
                ) : (
                  <img
                    className="no_image_avatar_product_details"
                    src={`${
                      productdetails?.thumbnail
                        ? `${productdetails?.thumbnail_url}`
                        : no_image_product_details
                    }`}
                  />
                )}
              </div>
            </div>

            <div className="col-md-7">
              <div>
                <h3 className="productName">{productdetails?.name}</h3>
                <div className="price-heart">
                  <div className="d-flex">
                    <p className="price">
                      <span className="for-prcie-color">Price :</span> $
                      {productdetails?.unit_price}
                      {productdetails?.current_stock > 0 ? null : (
                        <p className="price">Out of Stock</p>
                      )}
                    </p>
                  </div>

                  <div style={{ width: "2rem" }}>
                    <Heart
                      inactiveColor={"white"}
                      isActive={active}
                      onClick={() => AddWishListHandler()}
                      animationScale={1.25}
                      style={{ marginBottom: "1rem" }}
                    />
                  </div>
                </div>
                <div>
                  {/* <Rating onClick={handleRating} ratingValue={rating} /> */}
                  {/* <Rating
                    size={18}
                    readonly={true}
                    allowFraction={true}
                    ratingValue={rating}
                    // ratingValue={}
                  /> */}
                  {productdetails?.rating?.map((item, index) => {
                    return (
                      <Rating
                        size={18}
                        readonly={true}
                        ratingValue={
                          Math.round(item?.average) === 1
                            ? "20"
                            : Math.round(item?.average) === 2
                            ? "40"
                            : Math.round(item?.average) === 3
                            ? "60"
                            : Math.round(item?.average) === 4
                            ? "80"
                            : Math.round(item?.average) === 5
                            ? "100"
                            : null
                        }
                      />
                    );
                  })}
                  {/* <p className="reviews-text">({ReviewsDataRedux?.length}:all reiviews)</p> */}
                </div>
                <hr style={{ color: "#ffff" }} />
                <p
                  className="productDesc"
                  dangerouslySetInnerHTML={{ __html: productdetails?.details }}
                />
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-12 quantity-wrap">
                    <div className="text">Quantity</div>
                    <div className="qty-contaner">
                      <button
                        type="button"
                        className="btn qty-btn"
                        onClick={() => Decrement(productdetails?.id)}
                        disabled={quantity < 2}
                      >
                        -
                      </button>
                      <p className="qty-num">{quantity}</p>

                      <button
                        type="button"
                        className="btn qty-btn"
                        onClick={() => Increment(productdetails?.id)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-12 quantity-wrap">
                    <div className="text">Size</div>
                    <div className="product-size-select">
                      <select
                        name="size"
                        id="size"
                        className="sizeSelect"
                        defaultValue="Size"
                        onChange={(e) => {
                          setSizeData(e.target.value);
                        }}
                      >
                        {" "}
                        <option>Select size</option>
                        {productdetails?.choice_options.map(
                          (item, indexcho) => {
                            return (
                              <>
                                {item?.title === "Size" ? (
                                  <>
                                    {item?.options?.map((inner, index)  => {
                                      return (
                                        <option value={inner} key={index}>
                                          {inner}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : null}
                              </>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12 col-12 quantity-wrap product-color-wrap">
                    <div className="text">Colors</div>
                    <div className="product-colors">
                      {productdetails?.color_name?.map((item, indexcolr) => {
                        return (
                          <>
                            <label className="colors-names">
                              <div
                                style={{
                                  backgroundColor: item?.code,
                                  width: "30px",
                                  height: "30px",
                                  border: "1px solid",
                                }}
                              ></div>
                            </label>
                            <input
                              type="radio"
                              id={item?.name}
                              value={item?.name}
                              name="colors"
                              onChange={(e) => setColor(e.target.value)}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mt-4 mb-4">
                  <button
                    type="button"
                    className="btn AddBtn"
                    onClick={() => AddToCartHandler(productdetails)}
                  >
                    <FontAwesomeIcon
                      icon={faCartArrowDown}
                      className="cart-icon mr-2"
                    />
                    Add To Cart
                  </button>

                  <button
                    type="button"
                    className="btn sizeBtn"
                    onClick={() => setIsOpenModal(true)}
                  >
                    Size Chart
                  </button>
                </div>
                {/* <p className="listItems">SKU:</p>{" "}
                <p className="listItems">Product Type:</p>{" "} */}
                {/* {productdetails?.variation.map((item, indexva) => {
									return (
										<>
											<p className="listItems ml-2">NHFL1</p>
											<p className="listItems ml-2">{item?.sku}</p>

											<div className="list">
												<p className="listItems  ml-2">Lorem ipsum</p>
												<p className="listItems  ml-2">{item?.type}</p>
											</div>
										</>
									);
								})} */}
                {/* <div className="list">
                  <p className="listItems">Tag: </p>{" "}
                  <p className="listItems  ml-2"> T.shirt</p>
                </div> */}
              </div>
            </div>
          </div>
          <div className="bottom-container">
            <section className="ClientsReviewsSec">
              <div className="container">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="reviews-tab"
                      data-toggle="tab"
                      href="#reviews"
                      role="tab"
                      aria-controls="reviews"
                      aria-selected="true"
                    >
                      Products Description
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="specification-tab"
                      data-toggle="tab"
                      href="#specification"
                      role="tab"
                      aria-controls="specification"
                      aria-selected="false"
                    >
                      Reviews
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="reviews"
                    role="tabpanel"
                    aria-labelledby="reviews-tab"
                  >
                    <ProductDescriptionBox
                      productDetail={productdetails?.details}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="specification"
                    role="tabpanel"
                    aria-labelledby="specification-tab"
                  >
                    <div className="review-container">
                      <div>
                        <AllReviews
                          ReviewsDataRedux={ReviewsDataRedux}
                          productReview={productReview}
                          setProductReview={setProductReview}
                          productdetails={productdetails}
                          setProductDetails={setProductDetails}
                          handlePageClick={handlePageClick}
                          pageCount={pageCount}
                        />
                      </div>
                      <hr style={{ color: "#fff" }} />
                      <div>
                        <ReviewBox
                          name={name}
                          setName={setName}
                          email={email}
                          setEmail={setEmail}
                          comment={comment}
                          setComment={setComment}
                          SubmitRating={SubmitRating}
                          handleRating={handleRating}
                          rating={rating}
                          loading={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {/* ============== */}
      <PercentOffAdds />
      <NewsLetter />
      <Footer />
      <SizeModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        productdetails={productdetails}
        setProductDetails={setProductDetails}
      />
    </>
  );
}

export default ProductDetails;
