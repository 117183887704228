import React from "react";

function PriceFilter(props) {
  const {
    startPrice,
    setStartPrice,
    endPrice,
    setEndPrice,
    PriceFilterHandler,
    ResetFilter,
  } = props;

  return (
    <>
      {/* Price Filter Section  */}
      <div className="price-container mt-4">
        <div className="header">
          <p className="title">Filter By Price</p>
        </div>
        <div className="input-container">
          <div>
            <input
              type="number"
              id="from"
              value={startPrice}
              onChange={(e) => setStartPrice(e.target.value)}
              placeholder="From"
            />
          </div>
          <div>
            <input
              type="number"
              id="from"
              value={endPrice}
              onChange={(e) => setEndPrice(e.target.value)}
              placeholder="To"
            />
          </div>
        </div>
        <div className="filter-reset">
          <div>
            <button
              type="button"
              className="btn  Pricefilter-btn"
              onClick={(e) => PriceFilterHandler(e)}
            >
              Filter
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn  Pricefilter-btn"
              onClick={(e) => ResetFilter(e)}
            >
              Reset
            </button>
          </div>
        </div>
        <p className="text">
          Price : ${startPrice} — ${endPrice}
        </p>
      </div>
      {/* ================ */}
    </>
  );
}
export default PriceFilter;
