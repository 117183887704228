import React,{useState} from "react";
import "../../assets/css/Policy.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { useEffect } from "react";
import { getRefund } from "../../network/Network";

const Refund = () => {
  const [checked, setChecked] = useState("");
  const [refund ,setRefund] =useState("null")

  const handleSelectCategory = (id) => {
    setChecked(id);
  };
  // Refund Policy Api
  useEffect(() => {
		getRefund()
			.then((res) => {
				setRefund(res?.data?.data?.data);
			})
			.catch((err) => {
			});
	}, []);
  // ================================
  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader  page={"Refund Policy "}/>
      <div className="pos-rel POL">
        <div className="dark_black_div">
          <div className="container">
            <div className="row mt-4rem">
              <div className="col-lg-12 mb-5">
              <p
									className="f-16 font-roboto  text-start"
									dangerouslySetInnerHTML={{ __html: refund?.value }}
								/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Refund;
