import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/Signup.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import { signUp } from "../../redux/actions/AuthActions";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Signup = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [btnLoading, setBtnLoading] = useState("");
  const [checked, setChecked] = useState("");
  const [phoneerror, setPhoneError] = useState("");
  const [passerror, setPassError] = useState("");
  const [fnameerror, setFNameError] = useState("");
  const [showallerror, setShowAllError] = useState("");
  const [error, setError] = useState([]);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  //   Sign Up Post Api
  const SignUpHandler = async (e, event) => {
    e.preventDefault();
    setBtnLoading(true);
    const errors = [];
    if (!firstName) {
      errors.push({ firstName: "First Name is required" });
    } else if (firstName.length > 15) {
      errors.push({ firstName: "First name must be 15 characters or less" });
    }

    if (!lastName) {
      errors.push({ lastName: "Last Name is required" });
    } else if (lastName.length > 15) {
      errors.push({ lastName: "Last Name must be 15 characters or less" });
    }

    if (!email) {
      errors.push({ email: "Email is required" });
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      errors.push({ email: "Email is Invalid" });
    }

    if (!phone) {
      errors.push({ phone: "phone is required" });
    } else if (phone.length >= 11) {
      errors.push({ phone: "Phone number must be 11 characters or less" });
    }

    if (!address) {
      errors.push({ address: "address is required" });
    }

    if (!city) {
      errors.push({ city: "City is required" });
    }

    if (!zipCode) {
      errors.push({ zipCode: "zip Code is required" });
    }

    if (!country) {
      errors.push({ country: "country is required" });
    }

    if (!password) {
      errors.push({ password: "password is required" });
    } else if (password.length < 8) {
      errors.push({
        password: "Password length must be 8 characters or more”",
      });
    }

    if (!confirmPassword) {
      errors.push({ confirmPassword: "password is required" });
    } else if (confirmPassword != password) {
      errors.push({
        confirmPassword:
          "Your passwords do not match. Please make sure they match exactly.",
      });
    }
    setError(errors);

    let data = {
      f_name: firstName,
      l_name: lastName,
      email: email,
      phone: phone,
      password: password,
      street_address: address,
      country: country,
      city: city,
      zip: zipCode,
      confirm_password: confirmPassword,
    };
    setBtnLoading(false);
    let send = await dispatch(signUp(data, Navigate, setBtnLoading));
  };
  useEffect(() => {
    if (Token) {
      Navigate("/");
    }
  }, []);
  //   =================================
  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"Sign Up"} />
      {/* Sign Up Section  */}
      <div className="pos-rel signup">
        <div className="container dark_black_div">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h3 className="mt-4rem font-roboto f-31">
                Register Your Account
              </h3>
              <p className="f-16">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore.
              </p>

              <div className="form-signup px-3">
                <p className="font-mon-semibold mt-5">First Name</p>
                <input
                  type="text"
                  maxLength={15}
                  placeholder="Enter Your First Name*"
                  className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                  value={firstName}
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {error.map((errors, indexs1) => {
                  return (
                    <>
                      {errors?.firstName ? (
                        <p className="error-color" key={indexs1}>
                          {errors?.firstName}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <p className="font-mon-semibold mt-3">Last Name</p>
                <input
                  type="text"
                  maxLength={15}
                  placeholder="Enter Your Last Name*"
                  className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                  value={lastName}
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
                {error.map((errors, indexs2) => {
                  return (
                    <>
                      {errors?.lastName ? (
                        <p className="error-color" key={indexs2}>
                          {errors?.lastName}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <p className="font-mon-semibold mt-3">Email</p>
                <input
                  type="email"
                  placeholder="Enter Your Email*"
                  className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error.map((errors, indexs3) => {
                  return (
                    <>
                      {errors?.email ? (
                        <p className="error-color" key={indexs3}>
                          {errors?.email}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <p className="font-mon-semibold mt-3">Phone</p>
                <input
                  type="number"
                  maxLength={11}
                  placeholder="Enter Your Phone*"
                  className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                  value={phone}
                  required
                  onChange={(e) => setPhone(e.target.value)}
                />
                {error.map((errors, indexs4) => {
                  return (
                    <>
                      {errors?.phone ? (
                        <p className="error-color" key={indexs4}>
                          {errors?.phone}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <p className="font-mon-semibold mt-3">Address</p>
                <input
                  type="text"
                  placeholder="Enter Your Address*"
                  className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                  value={address}
                  required
                  onChange={(e) => setAddress(e.target.value)}
                />
                {error.map((errors, indexs5) => {
                  return (
                    <>
                      {errors?.address ? (
                        <p className="error-color" key={indexs5}>
                          {errors?.address}
                        </p>
                      ) : null}
                    </>
                  );
                })}
                <p className="error-color">{showallerror}</p>
                <div className="row">
                  <div className="col-lg-4">
                    <p className="font-mon-semibold mt-3">State</p>
                    <input
                      type="text"
                      placeholder="Enter State*"
                      className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                      value={city}
                      required
                      onChange={(e) => setCity(e.target.value)}
                    />
                    {error.map((errors, indexs6) => {
                      return (
                        <>
                          {errors?.city ? (
                            <p className="error-color" key={indexs6}>
                              {errors?.city}
                            </p>
                          ) : null}
                        </>
                      );
                    })}
                    <p className="error-color">{showallerror}</p>
                  </div>
                  <div className="col-lg-4">
                    <p className="font-mon-semibold mt-3">Zip Code</p>
                    <input
                      type="text"
                      placeholder="Enter Zip Code*"
                      className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                      value={zipCode}
                      required
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                    {error.map((errors, indexs7) => {
                      return (
                        <>
                          {errors?.zipCode ? (
                            <p className="error-color" key={indexs7}>
                              {errors?.zipCode}
                            </p>
                          ) : null}
                        </>
                      );
                    })}{" "}
                  </div>
                  <div className="col-lg-4">
                    <p className="font-mon-semibold mt-3">Country</p>
                    <input
                      type="text"
                      placeholder="Enter Country*"
                      className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                      value={country}
                      required
                      onChange={(e) => setCountry(e.target.value)}
                    />
                    {error.map((errors, indexs8) => {
                      return (
                        <>
                          {errors?.country ? (
                            <p className="error-color" key={indexs8}>
                              {errors?.country}
                            </p>
                          ) : null}
                        </>
                      );
                    })}{" "}
                  </div>
                  {/* 
                  <div className="col-lg-12">
                    <p className="font-mon-semibold mt-3">Street</p>
                    <input
                      type="text"
                      placeholder="Enter Street*"
                      className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                      value={address}
                      required
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    {error.map((errors, indexs5) => {
                      return (
                        <>
                          {errors?.address ? (
                            <p className="error-color" key={indexs5}>
                              {errors?.address}
                            </p>
                          ) : null}
                        </>
                      );
                    })}{" "}
                  </div> */}
                </div>
                <div className="confirm-password-icon">
                  <p className="font-mon-semibold mt-3">Password</p>
                  <input
                    type={values.showPassword ? "text" : "password"}
                    placeholder="Enter Your Password*"
                    className="form-control signup-fld f-13 font-mon-meduim rounded-0 p-3"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {error.map((errors, indexs9) => {
                    return (
                      <>
                        {errors?.password ? (
                          <p className="error-color" key={indexs9}>
                            {errors?.password}
                          </p>
                        ) : null}
                      </>
                    );
                  })}{" "}
                  <div className="icon-password">
                    <i
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      className="fa fa-eye"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="confirm-password-icon">
                  <p className="font-mon-semibold mt-3">Confirm Password</p>
                  <input
                    type={values.showPassword ? "text" : "password"}
                    placeholder="Enter Your Confirm Password"
                    className="form-control signup-fld f-13 font-mon-meduim rounded-0 p-3"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {error.map((errors, indexs10) => {
                    return (
                      <>
                        {errors?.confirmPassword ? (
                          <p className="error-color" key={indexs10}>
                            {errors?.confirmPassword}
                          </p>
                        ) : null}
                      </>
                    );
                  })}{" "}
                  <div className="icon-password">
                    <i
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      className="fa fa-eye"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <input type="checkbox" className="mt-4" />{" "}
                <span className="font-pop-reg f-15 mt-4 ms-2">
                  By clicking "create account", I consent to the privacy policy.
                </span>
                <div className="d-flex">
                  <div className="border-red mt-4">
                    <button
                      className="view_more btn f-17 font-roboto-medium"
                      onClick={(e) => SignUpHandler(e)}
                      disabled={btnLoading}
                    >
                      {btnLoading ? "Loading.." : "Submit"}
                    </button>
                  </div>
                </div>
                <div className="by_creating_acc">
                  <Link
                    to="/sign-in"
                    className="font-pop-reg f-15 clr-red mt-5 mb-5"
                  >
                    Already have an account?{" "}
                    <span className="text-white font-robot-medium f-15 ms-3">
                      Sign In
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============ */}
    </>
  );
};

export default Signup;
