import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../assets/css/giftcard.css";
import { Postgift } from "../../network/Network";
import { toast } from "react-toastify";
import StripeForm from "../../components/Stripe/StripeForm";
import UrlHeader from "../../components/Header/UrlHeader";
import swal from "sweetalert";

function Giftcard() {
  const [recipientname, setRecipientname] = useState();
  const [recipientemail, setRecipientEmail] = useState();
  const [senderemail, setSenderEmail] = useState();
  const [sendername, setSenderName] = useState();
  const [stripetoken, setStripeToken] = useState();

  const [amount, setAmount] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [cardToken, setCardToken] = useState("");
  const [error, setError] = useState([]);

  const GiftcardHandler = (e, cardToken) => {
    e.preventDefault();
    const errors = [];

    if (!recipientname) {
      errors.push({ recipientname: "Recipient Name is required" });
    }

    if (!recipientemail) {
      errors.push({ recipientemail: "Recipient Email is required" });
    }
    if (!senderemail) {
      errors.push({ senderemail: "Sender Email is required" });
    }

    if (!sendername) {
      errors.push({ sendername: "Sender Name is required" });
    }

    if (!amount) {
      errors.push({ amount: "Amount is required" });
    }

    if (!message) {
      errors.push({ message: "Message is required" });
    }

    // if(!cardToken){
    // 	errors.push({ message: "Message is required" });
    // }
    setError(errors);

    let data = {
      recipient_name: recipientname,
      recipient_email: recipientemail,
      sender_email: senderemail,
      sender_name: sendername,
      amount: amount,
      message: message,
      stripe_token: cardToken,
    };
    Postgift(data)
      .then((res) => {
        setLoading(false);
        // toast.success("your message is send");
        swal({
          text: "Gift Card Sent Successfully",
          timer: 2000,
          icon: "success",
        });
        setRecipientname("");
        setRecipientEmail("");
        setSenderEmail("");
        setSenderName("");
        setAmount("");
        setMessage("");
        setCardToken("");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Header />
      <UrlHeader page={"Gift Card"} />


      {/* Gift Card Form */}
      <section className="gift-card-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Card-form  */}
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-gift-car-input">
                      <input
                        type="text"
                        value={recipientname}
                        placeholder="Recipient name"
                        onChange={(e) => setRecipientname(e.target.value)}
                      />
                      {error.map((errors, indexgf1) => {
                        return (
                          <>
                            {errors?.recipientname ? (
                              <p className="error-color" key={indexgf1}>
                                {errors?.recipientname}
                              </p>
                            ) : null}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-gift-car-input">
                      <input
                        type="email"
                        value={recipientemail}
                        placeholder="Sender name"
                        onChange={(e) => setRecipientEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-gift-car-input">
                      <input
                        type="email"
                        value={senderemail}
                        placeholder="Recipient email"
                        onChange={(e) => setSenderEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-gift-car-input">
                      <input
                        type="text"
                        value={sendername}
                        placeholder="Sender email"
                        onChange={(e) => setSenderName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-gift-car-input">
                      <input
                        type="number"
                        value={amount}
                        placeholder="Amount"
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
										<div className="form-gift-car-input">
											<input type="text" />
										</div>
									</div> */}
                  <div className="col-md-12">
                    <div className="form-gift-car-input">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        value={message}
                        rows="3"
                        placeholder="Type your message here"
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <StripeForm
                    PaymentHandler={GiftcardHandler}
                    setCardToken={setCardToken}
                    cardToken={cardToken}
                    paynow="Send"
                  />

                  {/* <div className="col-md-12">
										<div className="form-btn">
											<button
												type="button"
												className="btn  btn-primary view_more font-15"
												onClick={(e) => GiftcardHandler(e)}
											>
												send
											</button>
										</div>
									</div> */}
                </div>
              </form>
            </div>
            {/* <div className="col-md-4">
							<div className="gift-card-price">
								<div className="gift-card-get-price">
									<h5>Gift Card</h5>
									<h5>$0.00</h5>
								</div>
								<div className="gift-card-text">
									<p>
										dear <span>recipient name</span>
									</p>
								</div>
								<div className="gift-card-input">
									<label>code :</label>
									<input type="text" placeholder="XXX-XXX-XXX" />
								</div>
							</div>
							<div className="gift-card-price gift-car-price-two">
								<div className="gift-balance">
									<h3>billing summary</h3>
								</div>
								<div className="gift-balance">
									<h4>gift card</h4>
									<h5>$0.00</h5>
								</div>
								<div className="gift-balance">
									<h4>total amount</h4>
									<h5>$0.00</h5>
								</div>
							</div>
						</div> */}
          </div>
        </div>
      </section>
      {/* ==================== */}

      <Footer />
    </>
  );
}

export default Giftcard;
