import React, { useEffect, useState } from "react";
import "../../assets/css/Policy.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { getTerms } from "../../network/Network";

const TermCondition = () => {
	const [checked, setChecked] = useState("");
	const [termssetting, setTermsSetting] = useState("null");

	const handleSelectCategory = (id) => {
		setChecked(id);
	};

	// Terms and Condition Get Api
	useEffect(() => {
		getTerms()
			.then((res) => {
				setTermsSetting(res?.data?.data?.data);
			})
			.catch((err) => {
			});
	}, []);
	// ==========================================
	return (
		<>
			<Header setChecked={setChecked} />
			<UrlHeader page={"Terms And Conditions "} />
			<div className="pos-rel POL">
				<div className="dark_black_div">
					<div className="container">
						<div className="row mt-4rem">
							<div className="col-lg-12">
								<p
									className="f-16 font-roboto  text-start"
									dangerouslySetInnerHTML={{ __html: termssetting?.value }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NewsLetter />
			<Footer />
		</>
	);
};

export default TermCondition;
