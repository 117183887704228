import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "../pages/Aboutus/About";
import AddressPage from "../pages/Address/AddressPage";
import ForgotEmail from "../pages/Auth/ForgotEmail";
import ForgotPasswordOtp from "../pages/Auth/ForgotPasswordOtp";
import Login from "../pages/Auth/Login";
import NewPassword from "../pages/Auth/NewPassword";
import Signup from "../pages/Auth/Signup";
import BlogDetails from "../pages/Blog/BlogDetails";
import BlogPage from "../pages/Blog/BlogPage";
import ProductCart from "../pages/Cart/ProductCart";
import Checkout from "../pages/Checkout/Checkout";
import Contact from "../pages/Contact/Contact";
import Faq from "../pages/Faqs/Faq";
import Home from "../pages/Home/Home";
import MyOrder from "../pages/Order/MyOrder";
import Cookie from "../pages/Policy/Cookie";
import Privacy from "../pages/Policy/Privacy";
import Refund from "../pages/Policy/Refund";
import TermCondition from "../pages/Policy/TermCondition";
import AllProductPage from "../pages/ProductPage/AllProductPage";
import HotDeals from "../pages/ProductPage/HotDeals";
import NewArrivals from "../pages/ProductPage/NewArrivals";
import ProductDetails from "../pages/ProductPage/ProductDetails";
import ProductPage from "../pages/ProductPage/ProductPage";
import Profile from "../pages/Profile/Profile";
import ShippingInfo from "../pages/Shipping/ShippingInfo";
import WishList from "../pages/WishList/WishList";
import ProtectedRoutes from "./ProtectedRoutes";
import Giftcard from "../pages/Gift-card/Giftcard";
import Thankyou from "../pages/thankyou/Thankyou";

function PublicRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="about-us" element={<About />} />
          <Route path="all-product-page" element={<AllProductPage />} /> 
          <Route path="products/:slug" element={<ProductPage />} />
          <Route path="products/:Id/:slug" element={<ProductPage />} />
          <Route path="hot-deals" element={<HotDeals />} />
          <Route path="new-arrivals" element={<NewArrivals />} />
          <Route path="thank-you" element={<Thankyou />} />

          <Route path="product-details/:slug" element={<ProductDetails />} />

          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-detail/:slug" element={<BlogDetails />} />
          <Route path="faqs" element={<Faq />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="refund-policy" element={<Refund />} />
          <Route path="cookie-policy" element={<Cookie />} />
          <Route path="terms-conditions" element={<TermCondition />} />

          <Route path="cart" element={<ProductCart />} />
          <Route path="check-out" element={<Checkout />} />

          <Route path="sign-up" element={<Signup />} />
          <Route path="sign-in" element={<Login />} />
          <Route path="forgot" element={<ForgotEmail />} />
          <Route path="forgot-password-otp" element={<ForgotPasswordOtp />} />
          <Route path="new-password" element={<NewPassword />} />
          <Route path="gift-card" element={<Giftcard />} />


          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="profile" element={<Profile />} />
            <Route path="my-orders" element={<MyOrder />} />
            <Route path="address-page" element={<AddressPage />} />
            <Route path="shipping-information" element={<ShippingInfo />} />
            <Route path="wishlist" element={<WishList />} />

          </Route>
   
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PublicRoutes;
