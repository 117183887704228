import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import swal from "sweetalert";
const stripePromise = loadStripe(
  // "pk_test_51Htt4kBWz16oBSwkY9pAtAjnUTMRJgTO2IT9kAFvhOWAr7azMnMD5ArGoYDVUVIw0j592abv5KvRm2HCAuw4p5Zt00415ahcE4",
  "pk_test_51NKezsLXoqth3oRzjYzUJvw89TnFRdreItA4etkRRsaxv5waEU952kMWaPPsLrI5mD77WddCJzqaOHUGpLNTaqt6009b2tNJcX"
);

const CheckoutForm = (props) => {
  const { PaymentHandler, paynow, setCardToken, cardToken, loading } = props;
  const [formData, setFormData] = useState({});
  const [payProcessing, setPayProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [paybutton, setPayButton] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);
    if (payload?.token?.id) {
      PaymentHandler(event, payload?.token?.id);
      return;
    } else {
      swal({
        title: " Oops!",
        text: " Something Went Wrong, Try Again",
        icon: "error",
      });
      return;
    }
    setPayProcessing(true);
    setDone(true);
    setPayProcessing(false);
  };

  return (
    <>
    {/* Stripe Form  */}
      <form onSubmit={handleSubmit}>
        <CardElement
          onChange={(e) => {
            if (e.complete) {
              setPayButton(false);
            } else {
              setPayButton(true);
            }
          }}
        />
        <br />

        <ul className="list-inline">
          <li>
            <button
              // type="submit"
              className="default-btn stripePay-Btn btn "
              disabled={!stripe || !elements}
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? "Loading..." : paynow}
            </button>
          </li>
        </ul>
      </form>
      {/* ============= */}
    </>
  );
};

const StripeForm = (props) => {
  const { PaymentHandler, paynow, setCardToken, cardToken, loading } = props;

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        PaymentHandler={PaymentHandler}
        paynow={paynow}
        // setCardToken={setCardToken}
        // cardToken={cardToken}
        loading={loading}
      />
    </Elements>
  );
};

export default StripeForm;
