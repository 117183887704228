import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/Cart.css";
import CartList from "../../components/Cart/CartList";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { cart1 } from "../../constant";
import { ApplyCoupons, Postyougftcard } from "../../network/Network";
import {
  ClearCart,
  DecrementProductQuantity,
  deleteCartItem,
  IncrementProductQuantity,
} from "../../redux/actions/CartActions";
import swal from "sweetalert";

const ProductCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CartData = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const [cartData, setCartData] = useState([CartData]);
  const [subTotal, setSubTotal] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [coupons, setCoupons] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [couponData, setCouponData] = useState([]);
  const [giftcard, setGiftcard] = useState([]);
  const [gifts, setGifts] = useState("");
  const [code, setCode] = useState("");
  const [checked, setChecked] = useState("");

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  // Total Amount
  const TotalAmount = () => {
    let i;
    let total = 0;
    for (i = 0; i < CartData.length; i++) {
      total = total+  CartData[i]?.price * CartData[i]?.quantity;
    }
    setSubTotal(total);
  };
  // ============

  useEffect(() => {
    TotalAmount();
  }, [CartData]);

  
  // Login Handler  
  const LoginHandler = (e) => {
    e.preventDefault();
    if (!isLogin) {
      swal({
        text: "Please First Login",
        timer: 2000,
        icon: "error",
      });
      navigate("/sign-in");
      return;
    }
  };
  // =============

  // Remove Cart Handler 
  const RemoveCartItem = (id) => {
    const items = cartData;
    let currentItem = id;
    if (items.length > 0) {
      setCartData(
        items.filter((item, index) => item?.productitem?.id !== currentItem)
      );
      dispatch(deleteCartItem(currentItem));
      //   setPostList(items.filter((item, index) => index !== currentItem));
    }
  };
  // ================

  const Increment = (id) => {
    dispatch(IncrementProductQuantity(id));
  };
  const Decrement = (id) => {
    dispatch(DecrementProductQuantity(id));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"Cart"} />
      {/* Product Cart Section  */}
      <section className="productCart">
        <div className="container">
          <div className="heading">
            <h3 className="text-center title font-40 fw-600 title">
              Product Cart
            </h3>
            <p className="desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore.
            </p>
          </div>
          <div className="tableDv">
            <table>
              <thead>
                <tr className="topbar">
                  <th className="productDetail">PRODUCT NAME</th>
                  <th className="Proprice">UNIT PRICE</th>
                  <th className="Size">SIZE</th>
                  <th className="qty">QUANTITY</th>
                  <th className="addcart">SUB TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {CartData?.map((item, index) => {
                  return (
                    <CartList
                      item={item}
                      index={index}
                      RemoveCartItem={RemoveCartItem}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      Increment={Increment}
                      Decrement={Decrement}
                    />
                  );
                })}
              </tbody>
            </table>

            <div className="row pt-5">
              <div className="col-md-6">
                <div className="row">
                </div>
              </div>

              <div className="col-md-6 text-right">
                <div className="productSummary">
                  <div className="subTotal">
                    <h6 className="rightt">Subtotal</h6>
                    <h6>Estimated Shipping Cost</h6>
                  </div>
                  <div className="shipping">
                    <h6 className="price">${subTotal}</h6>
                    <h6>Free Shipping</h6>
                  </div>
                </div>
                <div className="Total">
                  <div className="property">
                    <p>TOTAL COST</p>
                  </div>
                  <div className="value">
                    <p>
                      ${subTotal}
                      {/* $
                      {couponData?.discount 
                        ? subTotal - couponData?.discount
                        : subTotal} */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-baseline">
            <div className="col-md-6">
              <div className="button-group">
                <Link to="/all-product-page" className="btn">
                  Continue Shopping
                </Link>
                <a className="btn grey" onClick={() => dispatch(ClearCart())}>
                  Clear
                </a>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <div className="proceed">
                {isLogin && CartData?.length > 0 ? (
                  <Link
                    to={"/check-out"}
                    state={{
                      data: {
                        CartData: CartData,
                        total: subTotal,
                        couponData: couponData,
                        giftcard: giftcard,
                      },
                    }}
                    className="btn"
                  >
                    Proceed To Checkout
                  </Link>
                ) : (
                  <button className="btn" onClick={(e) => LoginHandler(e)}>
                    Continue To checkout
                  </button>
                )}
                <h6 style={{ color: "#989898" }}>
                  You’ll still have a chance to review your order
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============== */}
      <NewsLetter />
      <Footer />
    </>
  );
};

export default ProductCart;
