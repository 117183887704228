import React, { useEffect } from "react";
import "../../assets/css/FollowUs.css";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { cc1, follow1, follow2 } from "../../constant";
import Aos from "aos";
import { Link } from "react-router-dom";

const FollowUs = () => {
  useEffect(() => {
    Aos.init();
  },[])

  return (
    <div className="followUs">
      <div className="gradient_red p-3 py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center "
             data-aos="fade-right"
             data-aos-offset="300"
             data-aos-easing="ease-in-sine"
            >
              <p className="f-51 font-roboto text-white">
                Follow Us On Facebook
              </p>
              <p className="f-17 font-roboto text-white">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
              <div className="border-red">
                <Link target="_blank" to={"http://www.facebook.com/"}>
                <button className="view_more btn font-14 font-roboto fw-light ">
                  Visit Now <FacebookRoundedIcon />
                </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex res-flx-col ms-4 no_ms">
                <div className="card card_follow h-100 me-4 mt-lpt-3 mt-res-5"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-offset="300"
                >
                  <img src={follow1} alt="Card image cap" />
                  <div className="card-body">
                    <div className="d-flex justify-spacebetween align-center">
                      <div className="d-flex">
                        <img
                          className="card-img-top"
                          src={cc1}
                          alt="Card image cap"
                        />
                        <div className="ms-2">
                          <p className="f-14 font-roboto fw-bold mb-0">
                            You're beautiful
                          </p>
                          <p className="f-9 font-roboto mb-0">
                            @yourbeautiful . 2 days ago
                          </p>
                        </div>
                      </div>
                      <div>
                        <FacebookRoundedIcon className="fb_color" />
                      </div>
                    </div>
                    <p className="f-15 mt-2 font-roboto">
                      Lorem Ipsum is simply dummy text
                    </p>
                    <p className="f-13 font-roboto colr_hashtag">
                      #love #instagood #photooftheday #fashion #beautiful #happy
                      #cute #tbt #followme #summer
                    </p>
                  </div>
                </div>
                <div className="card card_follow h-100 mt-5"
                     data-aos="fade-up"
                     data-aos-anchor-placement="top-bottom"
                     data-aos-offset="300"
                >
                  <img src={follow2} alt="Card image cap" />
                  <div className="card-body">
                    <div className="d-flex justify-spacebetween align-center">
                      <div className="d-flex">
                        <img
                          className="card-img-top"
                          src={cc1}
                          alt="Card image cap"
                        />
                        <div className="ms-2">
                          <p className="f-14 font-roboto fw-bold mb-0">
                            You're beautiful
                          </p>
                          <p className="f-9 font-roboto mb-0">
                            @yourbeautiful . 2 days ago
                          </p>
                        </div>
                      </div>
                      <div>
                        <FacebookRoundedIcon className="fb_color" />
                      </div>
                    </div>
                    <p className="f-13 mt-2 font-roboto">
                      Lorem Ipsum is simply dummy text
                    </p>
                    <p className="f-13 font-roboto colr_hashtag">
                      #love #instagood #photooftheday #fashion #beautiful #happy
                      #cute #tbt #followme #summer
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowUs;
