import React, { useEffect } from "react";
import { useState } from "react";
import { GetAllCategories } from "../../network/Network";
import { Link } from "react-router-dom";

function TopSlider(props) {
  const { item, indextop, handleSelectCategory } = props;

  return (
    <>
      <div>
        <Link to={`/products/${item?.slug}`}
         onClick={() => handleSelectCategory(item?.id)} 
        >
        <div className="main-container" key={indextop}>
          <img
            src={item?.image_url}
            alt=""
            className="image-fluid image-width"
          />
          <div className="category-container">
            <p
              className="subTitle"
            >
              {item?.name}
            </p>
          </div>
        </div>
        </Link>
      </div>
    </>
  );
}

export default TopSlider;
