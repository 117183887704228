import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { ApplyCoupons, Postyougftcard } from "../../network/Network";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function OrderSummary(props) {
  const {
    promoCode,
    setPromoCode,
    CheckOutData,
    ApplyCouponsHandler,
    Applygiftacardhandler,
    coupons,
    setCoupons,
    loading,
    setLoading,
    code,
    setCode,
    couponData,
    setCouponData,
    giftcard,
    setGiftcard,
    result,
    load,
  } = props;
  const CartData = useSelector((state) => state.CartReducer.cartData);

  // const [couponData, setCouponData] = useState([]);
  // const [giftcard, setGiftcard] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [code, setCode] = useState("");
  // const [coupons, setCoupons] = useState("");
  // const [load, setLoad] = useState(false);
  // const [subTotal, setSubTotal] = useState(0);

  // let result = CheckOutData?.couponData?.min_purchase
  //   ? CheckOutData?.total - CheckOutData?.couponData?.discount
  //   : CheckOutData?.giftcard?.remaining_amount
  //   ? CheckOutData?.total - CheckOutData?.giftcard?.remaining_amount
  //   : CheckOutData?.couponData?.min_purchase &&
  //     CheckOutData?.giftcard?.remaining_amount
  //   ? CheckOutData?.total -
  //     CheckOutData?.couponData?.discount -
  //     CheckOutData?.giftcard?.remaining_amount
  //   : CheckOutData?.total;

  return (
    <>
      {/* Order Section  */}
      <p className="mt-4rem font-roboto-medium f-20 mb-4">Order Summary</p>
      <div className="gift-card-coupon-dv">
        <div className="row coupon-and-gift-card">
          <div className="col-md-12">
            <div className="discountCode">
              <div className="applyCoupon">
                <input
                  type="text"
                  placeholder="Enter your coupon code if you have one."
                  value={coupons}
                  onChange={(e) => setCoupons(e.target.value)}
                />
                <button
                  onClick={(e) => ApplyCouponsHandler(e)}
                  disabled={loading}
                >
                  {loading ? "Loading.." : "Apply"}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="discountCode gift-card-code">
              <div className="applyCoupon">
                <input
                  type="text"
                  placeholder="Enter your gift code if you have one."
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <button onClick={(e) => Applygiftacardhandler(e)}>
                  {load ? "Loading.." : "Apply"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ */}

      {/* Product All details Section  */}
      <div className="order-box p-2">
        <div className="d-price">
          <p className="font-roboto-bold f-17">Product Details</p>
        </div>
        <div className="summary-inner">
          {CheckOutData?.CartData?.map((item, index) => {
            return (
              <div className="d-price align-center p-price mb-4" key={index}>
                <div className="d-flex align-center res-down-product">
                  <img
                    className="product_detail_image "
                    src={item?.productitem?.thumbnail_url}
                    alt="product_image"
                  />

                  <p className="ms-3 f-13 font-mon-semibold res-set-lpt">
                    {item?.productitem?.name}
                    <p className="mb-0">
                      {" "}
                      <span>({item?.quantity})</span>
                      <span>({item?.color})</span>
                      <span>({item?.size})</span>


                    </p>
                  </p>
                </div>

                <p className="f-17 font-mon-semibold">
                  ${item?.price}
                </p>
              </div>
            );
          })}
        </div>
        <div className="d-price">
          <p className="f-17 font-roboto-regular">Item Total :</p>
          <p className="f-17 font-roboto-regular">${CheckOutData?.total}</p>
        </div>
        <div className="d-price">
          <p className="f-17 font-roboto-regular">Shipment & Delivery :</p>
          <p className="f-17 font-roboto-regular">$0</p>
        </div>
        <div className="d-price">
          <p className="f-17 font-roboto-regular">Promo Applied :</p>
          <p className="f-17 font-roboto-regular">
            -$
            {couponData?.discount ? couponData?.discount : "0"}
          </p>
        </div>

        <div className="d-price brdr-btm-light pb-3">
          <p className="f-17 font-roboto-regular">Gift card discount:</p>
          <p className="f-17 font-roboto-regular">
            -$
            {giftcard?.remaining_amount ? giftcard?.remaining_amount : "0"}
          </p>
        </div>

        <div className="d-price mt-4">
          <p className="f-20 font-roboto-bold">Order Total :</p>
          <p className="f-20 font-roboto-bold">
            {/* {CheckOutData?.total >= CheckOutData?.couponData?.min_purchase ? (
              <>
                {`${CheckOutData?.total}` -
                  `${CheckOutData?.couponData?.discount}`}
              </>
            ) :  (
              CheckOutData?.total
            )} */}
            ${result}
          </p>
        </div>
      </div>
      {/* ====================== */}
    </>
  );
}

export default OrderSummary;
