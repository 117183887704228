import {
  faCartShopping,
  faEye,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { AddWishList } from "../../network/Network";
import { WishListData } from "../../redux/actions/AuthActions";
import { AddToCart } from "../../redux/actions/CartActions";
import { useState } from "react";
import swal from "sweetalert";

function ProductCard(props) {
  const { item, index } = props;
  const [rating, setRating] = useState(0);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const allStates = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);

  const handleRating = (rate) => {
    setRating(rate);
  };

  // const AddToCartHandler = (item) => {
  //   let checkItemAlreadyExist = allStates.filter((val) => val?.id == item?.id);
  //   if (checkItemAlreadyExist.length > 0) {
  //     // toast.info("Item Already Exist in Cart");
  //     swal("Item Already Exist in Cart");

  //     return;
  //   } else {
  //     let colorData = JSON.parse(item?.colors);
  //     let data = {
  //       id: item?.id,
  //       price: item?.unit_price,
  //       quantity: 1,
  //       color: colorData[0],
  //       productitem: item,
  //     };
  //     dispatch(AddToCart(data));
  //     toast.success("Product Add to your Cart");
  //   }
  // };

  // Wishlist Section
  const AddWishListHandler = (e) => {
    e.preventDefault();
    let data = {
      product_id: item?.id,
    };
    AddWishList(data, Token)
      .then((res) => {
        swal({
          text: "Product Add to your Wishlist.",
          timer: 2000,
          icon: "success",
        });
      })
      .catch((err) => {
        swal("ALert!", err?.response?.data?.message, "info");
      });
  };
  // =================
  return (
    <>
      <div className="card" key={index}>
        <a
          // to={`/productdetails/${item?.id}`}
          // state={{ data: item }}
          className="card-image"
        >
          <img
            src={`${item?.thumbnail_url}`}
            alt=""
            className="image-fluid image-width"
          />
          <div className="pop-cardHover">
            <div className="inner-hoverDiv">
              <FontAwesomeIcon
                icon={faHeart}
                className="social-color icon-hover"
                onClick={(e) => AddWishListHandler(e)}
              />
              <FontAwesomeIcon
                icon={faEye}
                className="social-color icon-hover"
                onClick={() =>
                  Navigate(`/product-details/${item?.slug}`, {
                    state: { data: item },
                  })
                }
              />
              {/* <FontAwesomeIcon
								icon={faCartShopping}
								className="social-color icon-hover"
								onClick={() => AddToCartHandler(item)}
							/> */}
            </div>
          </div>
        </a>
        <div className="card-inner">
          <div className="content-top">
            <Link
              to={`/product-details/${item?.slug}`}
              state={{ data: item }}
              className="title"
            >
              {item?.name}
            </Link>
            <h4 className="price">{item?.unit_price}</h4>
          </div>
          <div>
            <p
              className="desc"
              dangerouslySetInnerHTML={{ __html: item?.details }}
            />
          </div>
          <div>
            {/* <Rating readonly={true} onClick={handleRating} rating={rating} /> */}
            {/* {productdetails?.rating?.map((item, index) => {
              return ( */}
                <Rating
                  size={18}
                  readonly={true}
                  ratingValue={
                    Math.round(item?.rating[0]?.average) === 1
                      ? "20"
                      : Math.round(item?.rating[0]?.average) === 2
                      ? "40"
                      : Math.round(item?.rating[0]?.average) === 3
                      ? "60"
                      : Math.round(item?.rating[0]?.average) === 4
                      ? "80"
                      : Math.round(item?.rating[0]?.average) === 5
                      ? "100"
                      : null
                  }
                />
              {/* );
            })} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
