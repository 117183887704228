import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import ViewOrderModal from "../../components/Modal/ViewOrderModal";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import SideBar from "../../components/SideBar/SideBar";
import { footerB1 } from "../../constant";
import { GetAllOrders } from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

function MyOrder() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [checked, setChecked] = useState("");
  const [myOrdersData, setMyOrdersData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(true);
  const [pageCount, setPageCount] = useState();
  const [current_page, setCurrent_page] = useState(1);
  const [selectedData, setSelectedData] = useState();

  const handleSelectCategory = (id) => {
    setChecked(id);
  };
  // Pagination
  const handlePageClick = async (data) => {
    setCurrent_page(data?.selected + 1);
  };
  // ===========

  const viewOrderHandler = (item) => {
    setIsOpenModal(true);
    setSelectedData(item);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get all orders
  useEffect(() => {
    setSpinLoad(true);
    GetAllOrders(current_page, Token)
      .then((res) => {
        setMyOrdersData(res?.data?.data?.data);
        const total = res?.data?.data?.total;
        const limit = res?.data?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [current_page]);
  // =================

  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"My Orders"} />
      {/* My Order Section  */}
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2 className="page-name">My Orders</h2>
              <SideBar />
            </div>
            <div className="col-md-9">
              <section className="bookmark">
                <div className="heading">
                  <h3 className="text-center title font-40 fw-600">
                    My Orders
                  </h3>
                </div>
                <div className="tableDv">
                  {spinLoad ? (
                    <>
                      <div className="loader-container">
                        <SpinnerCircular size="80px" color="#d60012" />
                      </div>
                    </>
                  ) : (
                    <>
                      <table>
                        <thead>
                          <tr className="topbar">
                            <th>OrderNo</th>
                            <th>Total</th>
                            <th>Status</th>
                            <th>Payment Status</th>
                            <th>View</th>
                          </tr>
                        </thead>

                        {myOrdersData?.map((item, index) => {
                          return (
                            <tr className="products" key={item?.id}>
                              {/* <td className="myOrder-delete">
                              <div className="deleteBtn">
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </td> */}
                              <td>
                                <h5 className="ProductName">
                                  {item?.customer_id}
                                </h5>
                              </td>
                              <td>
                                <h5 className="ProductName">
                                  {item?.order_amount}
                                </h5>
                              </td>
                              <td>
                                <h5 className="ProductName">
                                  {item?.order_status}
                                </h5>
                              </td>

                              <td>
                                <h5 className="ProductName">
                                  {item?.payment_status}
                                </h5>
                              </td>

                              <td className="Proprice">
                                <button
                                  className="btn ViewDetails"
                                  onClick={() => viewOrderHandler(item)}
                                >
                                  View Details
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                      <div className="paginatio-count">
                        <div className="pagination-container mt-5">
                          <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                        <div className="product-remaining">
                          {myOrdersData?.length > 0 ? (
                            <p className="total-pages">
                              {myOrdersData?.length} PRODUCTS 1 -{" "}
                              {myOrdersData?.length}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="row align-items-baseline">
                  <div className="col-md-8">
                    <div className="button-group">
                      <Link to="/all-product-page" className="btn">
                        Continue Shopping
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6 text-right"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* ============ */}
      <NewsLetter />
      <Footer />
      <ViewOrderModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        selectedData={selectedData}
      />
      cl
    </>
  );
}

export default MyOrder;
