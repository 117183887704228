import Aos from "aos";
import React, { useEffect, useState } from "react";
import Avail from "../../components/Avail/Avail";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import OurTeamCard from "../../components/OurTeam/OurTeamCard";
import {
  about2,
  add1,
  avail,
  new1,
  new2,
  team1,
  team2,
  team3,
  team4,
} from "../../constant";
import { Aboutusget, OurTeam, PageData } from "../../network/Network";
import { Link } from "react-router-dom";

function About(props) {
  const [aboutData, setAboutData] = useState();
  const [aboutget, setAboutGet] = useState();
  const [getteam, setGetTeam] = useState([]);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // About Get Api
  useEffect(() => {
    Aboutusget().then((res) => {
      setAboutGet(res?.data?.data);
    });
  }, []);
  // =============

  // Meet Our Team Get Api
  useEffect(() => {
    OurTeam().then((res) => {
      setGetTeam(res?.data?.data?.social.data);
    });
  }, []);
  // ==============
  return (
    <>
      <Header />
      <UrlHeader page={"About us"} />
      {/* About Us Section  */}
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="inner-container"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h2 className="about-title">
                  {/* <span>What</span> we do */}
                  {aboutget?.section_1_heading}
                </h2>
                <p
                  className="para mt-4"
                  dangerouslySetInnerHTML={{
                    __html: aboutget?.section_1_description,
                  }}
                />

                <div className="mt-3">
                  <Link to="/all-product-page">
                    <button type="button" className="btn btn-primary">
                      Shop Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="right-imgbox"
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img
                  src={aboutget?.section_1_image_url}
                  alt=""
                  className="image-fluid image-width"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============ */}

      {/* Shop Now Section  */}
      <div
        className="AddsBanner-container"
        data-aos="flip-up"
        data-aos-duration="3000"
      >
        <div className="inner-container">
          <h4>{aboutget?.section_2_heading}</h4>
          <div className="button-container">
            <div>
              <Link to="/all-product-page">
                <button type="button" className="btn btn-primary">
                  Shop Now
                </button>
              </Link>
            </div>
            <div>
              <Link to="/contact-us">
                <button type="button" className="btn btn-primary">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>
        <img src={add1} className="image-fluid image-width" alt="" />
      </div>
      {/* =========== */}
      {/* New Arrivals  */}
      <div className="about-newarrival">
        <div className="container">
          <div className="row">
            <div
              className="col-md-3"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="right-imgbox1">
                <img
                  src={aboutget?.section_3_image_1_url}
                  alt=""
                  className="image-fluid image-width"
                />
              </div>
            </div>
            <div
              className="col-md-3"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="right-imgbox2">
                <img
                  src={aboutget?.section_3_image_2_url}
                  alt=""
                  className="image-fluid image-width"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="inner-container"
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h2 className="about-title">{aboutget?.section_3_heading}</h2>

                <p
                  className="para mt-4"
                  dangerouslySetInnerHTML={{
                    __html: aboutget?.section_3_description,
                  }}
                />

                <div>
                  <Link to="/all-product-page">
                    <button type="button" className="btn btn-primary">
                      View Categories
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== */}
      {/* New arrival end  */}
      <Avail page={"about"} aboutget={aboutget} setAboutGet={setAboutGet} />
      {/* Team  */}
      <div className="ourTeam">
        <div className="container">
          <h2 className="title">
            <span>Meet</span> our Team
          </h2>
          <div className="row">
            {getteam?.map((item, index) => {
              return <OurTeamCard item={item} index={index} />;
            })}
          </div>
        </div>
      </div>
      {/* ================= */}
      <NewsLetter />
      <Footer />
    </>
  );
}

export default About;
