export const ApiUrl =

  "https://developer-ourbase-camp.com/youarebeautiful/api/v1" ||
  // "https://youarebeautiful.developer-ourbase-camp.com/api/v1" ||
  "http://localhost:4000";

export const ImageUrl =
  "http://developer-ourbase-camp.com/youarebeautiful/storage/app/public/product" ||
  "http://localhost:4000";

// export const ApiUrl =
//   "http://192.168.3.96/trading/api/v1" || "http://localhost:4000";
// export const ImageUrl =
//   "http://192.168.3.96/trading" || "http://localhost:4000";