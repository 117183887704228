import React from "react";
import "../../assets/css/Reviews.css";
import { p1 } from "../../constant";
import SingleReviewCard from "../Card/SingleReviewCard";

function AllReviews(props) {
  const {
    productReview,
    setProductReview,
    ReviewsDataRedux,
    ParamData,
    handlePageClick,
    pageCount,
    productdetails,
    setProductDetails,
  } = props;
  return (
    <>
    {/* Reviews Section  */}
      <section className="ClientsReviewsSec">
        <div className="container">
          <div className="tab-content" id="myTabContent">
            <div className="head">
              <h3>Reviews</h3>
              <h5 className="total-comments"> {ReviewsDataRedux?.length} {" "}Reviews</h5>
            </div>
            {ReviewsDataRedux?.map((item, index) => {
                return <SingleReviewCard item={item} index={index} />;
              })}
          </div>
        </div>
      </section>
      {/* ============== */}
    </>
  );
}

export default AllReviews;
