import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ClearCart,
  IncrementProductQuantity,
} from "../../redux/actions/CartActions";
import swal from "sweetalert";
import { useState } from "react";

function CartList(props) {
  const {
    item,
    index,
    RemoveCartItem,
    quantity,
    setQuantity,
    Increment,
    Decrement,
  } = props;
  const Token = useSelector((state) => state.AuthReducer.token);
  const UserRedux = useSelector((state) => state.AuthReducer.users);
  const [sizeData, setSizeData] = useState("");
  const [color, setColor] = useState("");

  const dispatch = useDispatch();

  // Filter Variation Handler
  const filterVariationQty = (item) => {
    let filterSize;
    if (item?.productitem?.choice_options?.length > 0) {
      filterSize = item?.productitem?.choice_options.filter(
        (val) => val?.title === "Size"
      );
      if (filterSize?.length > 0) {
        if (filterSize[0]?.options?.length > 0) {
          if (!item?.size) {
            swal({
              title: " Oops!",
              text: "Please select size",
              icon: "error",
            });
            return;
          }
        }
      }
    }
    if (item?.productitem?.color_name?.length > 0) {
      if (!item?.color) {
        swal({
          title: " Oops!",
          text: "Please select Color",
          icon: "error",
        });
        return;
      }
    }
    let Make_variation = `${item?.color}-${item?.size}`;
    let filterVariation = item?.productitem?.variation?.filter(
      (val) => val?.type === Make_variation
    );
    if (filterVariation?.length > 0) {
      //   let data = {
      //     id: item?.id,
      //     price: filterVariation[0]?.price,
      //     quantity: quantity,
      //     color: color ? color : null,
      //     size: sizeData,
      //     productitem: item,
      //   };
      if (item?.quantity > filterVariation[0]?.qty) {
        swal({
          title: " Oops!",
          text: "Selected quantity is not available..",
          icon: "error",
        });
        return;
      }
      if (item?.quantity < filterVariation[0]?.qty) {
        dispatch(IncrementProductQuantity(item?.id));
        return;
      }
    }
  };
  // ================
  // let checkItemAlreadyExist = allStates.filter((val) => val?.id === item?.id);
  // if (checkItemAlreadyExist.length > 0) {
  // 	swal("Item Already Exist in Cart");
  // } else {
  // 	// let colorData = JSON.parse(ParamData?.colors);
  // 	let data = {
  // 		id: item?.id,
  // 		price: item?.unit_price,
  // 		quantity: quantity,
  // 		color: color ? color : null,
  // 		size: sizeData,
  // 		productitem: item,
  // 	};
  // 	dispatch(AddToCart(data));
  // }

  return (
    <>
    {/* Product Cart Section  */}
      <tr className="products cart" key={item?.id}>
        <td className="productDetail">
          <div className="product-detail">
            <div
              className="deleteBtn"
              onClick={(e) => RemoveCartItem(item?.id)}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <div className="imgBox">
              <figure>
                <img
                  src={`${item?.productitem?.thumbnail_url}`}
                  alt=""
                  className="image-fluid image-width"
                />
              </figure>
            </div>
            <div className="name">
              <p className="font-13">{item?.productitem?.name}</p>
            </div>
          </div>
        </td>
        <td className="priceText">
          <h5 className="font-15">${item?.price}</h5>
        </td>

        <td>
          {item?.size ? <h4 className="variation-type">{item?.size}</h4> : null}
        </td>
        <td className="qty">
          <div className="quantity-inner">
            <button
              className="btn increment-Decrement in-de1 ml-2"
              onClick={() => Decrement(item?.id)}
              disabled={item?.quantity <= 1 || item?.type == "Make_variation"}
            >
              -
            </button>
            {item?.quantity}

            <button
              className="btn increment-Decrement in-de2  mr-2"
              onClick={() => filterVariationQty(item)}
              //   disabled={
              //     filterVariationQty(item)
              //   }
            >
              +
            </button>
          </div>
        </td>
        <td className="addcart">
          <div>
            <div className="cartBtn">
              <p>{item?.quantity * item?.price}</p>
            </div>
          </div>
        </td>
      </tr>
      {/* ============== */}
    </>
  );
}

export default CartList;
