import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function ViewOrderModal(props) {
  const { setIsOpenModal, isOpenModal, selectedData, setSelectedData } = props;
  return (
    <>
    {/* View Order Section  */}
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className=" orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModal(false)}
        />
        <div className="inner-content">
          <h2 style={{ textAlign: "center" }}>Order Details</h2>
          <table className="order-modal-table">
            <thead>
              <tr className="topbar">
                <th></th>
                <th className="productDetail">Product Name</th>
                <th className="Proprice">Amount</th>
                <th className="Proprice">Quantity</th>
                <th className="productDetail">OrderNo</th>
                <th className="productDetail">Status</th>
              </tr>
            </thead>

            <tbody>
              {selectedData?.details?.map((item, index) => {
                return (
                  <tr className="products modal-padding" key={index}>
                    <td className="Proprice">
                      <figure className="modal-productImg">
                        <img
                          src={`${item?.product?.thumbnail_url}`}
                          alt=""
                          className="image-fluid image-width"
                        />
                      </figure>
                    </td>
                    <td className="productDetail">
                      <div className="product-detail">
                        <div className="name">
                          <p className="font-13">{item?.product?.name}</p>
                        </div>
                      </div>
                    </td>
                    <td className="name">
                      <p className="font-13"> {item?.price}</p>
                    </td>
                    <td className="name">
                      <p className="font-13"> {item?.qty}</p>
                    </td>
                    <td className="name">
                      <p className="font-13">{item?.order_id}</p>
                    </td>
                    <td className="Proprice">
                      <p className="font-13"> {item?.delivery_status}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* All amounts  */}
          <div className="row">
            <div className="col-md-12">
              <div className="all-amount-dv">
                <div className="amount-dv">
                  <span className="for-head-bold">Total:</span>
                  <span>${selectedData?.order_amount}</span>
                </div>
                <div className="amount-dv">
                  <span className="for-head-bold">Discount:</span>
                  <span>${selectedData?.discount_amount}</span>
                </div>

                <div className="amount-dv">
                  <span className="for-head-bold">Gift Card Amount:</span>
                  <span>${selectedData?.gift_card_amount}</span>
                </div>

                <div className="amount-dv">
                  <span className="for-head-bold">Total Amount :</span>
                  <span>
                    {" "}
                    ${" "}
                    {selectedData?.order_amount -
                      (selectedData?.gift_card_amount +
                        selectedData?.discount_amount)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* ==================== */}
        </div>
      </Modal>
      {/* ========== */}
    </>
  );
}

export default ViewOrderModal;
