import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import "../../assets/css/Forgot.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotPassword } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import { Password } from "@mui/icons-material";

const ForgotEmail = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const [checked, setChecked] = useState("");
  const [error, setError] = useState([]);

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  // Forget Password Handler 
  const ForgetPasswordHandler = async (e) => {
    e.preventDefault();
    const errors = [];
    if (!email) {
      errors.push({ email: "Email is required" });
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      errors.push({ email: "Email is Invalid" });
    }
    setError(errors);

    let data = { email: email };
    setTimeout(async () => {
      setLoading(false);
      let send = await dispatch(forgotPassword(data, Navigate));
      // Navigate("/forgot-password-Otp", { state: email });
      // Navigate("/newpassword");
      // window.location.href = "/newpassword";
    }, 600);
  };
  // ===================

  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"Forgot Password"} />

      {/* Forget Password Section  */}
      <div className="forgot_password">
        <div>
          <div>
            <div className="dark_black_div">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h3 className="mt-4rem font-roboto f-31">
                      Create New Password
                    </h3>
                    <p className="f-16 font-roboto-regular mt-3">
                      Enter Your Email Here
                    </p>
                    <div className="form-signup px-3">
                      <input
                        type="email"
                        placeholder="Enter Email"
                        className="form-control p-3 rounded-0 signup-fld f-15 font-roboto-regular"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {error.map((errors, indexl1) => {
                        return (
                          <>
                            {errors?.email ? (
                              <p className="error-color" key={indexl1}>
                                {errors?.email}
                              </p>
                            ) : null}
                          </>
                        );
                      })}
                      <div className="d-flex">
                        <button
                          className="btn-danger font-roboto-medium brbd-no-rad bg-red btn p-3 font-18 form-control font-roboto mt-4"
                          onClick={(e) => ForgetPasswordHandler(e)}
                          disabled={loading}
                        >
                          {loading ? "Loading.." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== */}
    </>
  );
};

export default ForgotEmail;
