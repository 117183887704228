import React, { useEffect, useState } from "react";
import "../../assets/css/Blog.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { blog1, blog2, blog3 } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { GetNews } from "../../network/Network";
import { ImageUrl } from "../../network/ApiUrl";
import { SpinnerCircular } from "spinners-react";
import moment from "moment";

function BlogPage() {
  const [newsData, setNewsData] = useState([]);
  const [checked, setChecked] = useState("");
  const [spinLoad, setSpinLoad] = useState(false);
  const handleSelectCategory = (id) => {
    setChecked(id);
  };
  // Get News
  useEffect(() => {
    setSpinLoad(true);
    GetNews()
      .then((res) => {
        setNewsData(res?.data?.data?.blogs);
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, []);
  //   ===============

  return (
    <>
      <Header setChecked={setChecked} />
      <UrlHeader page={"Blogs"} />
      {/* Blogs Section  */}
      <div className="blog-page">
        <div className="container">
          <div className="row">
            {spinLoad ? (
              <>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#d60012" />
                </div>
              </>
            ) : (
              <>
                {newsData?.map((item, index) => {
                  return (
                    <div className="col-md-4 mb-4">
                      <div className="card h-100">
                        <div className="image-box">
                          <img
                            src={item?.image_url}
                            alt=""
                            className="image-fluid image-width"
                          />
                        </div>
                        <div className="header">
                          <p className="mr-2">Blogs</p>
                          <p>
                            {""}
                            {moment(item?.created_at).format(
                              "MMMM Do YYYY , h:mm a"
                            )}
                          </p>
                        </div>
                        <p className="desc">{item?.title}</p>
                        <Link
                          to={`/blog-detail/${item?.slug}`}
                          state={{ data: item }}
                          className="readMore"
                        >
                          Read more{" "}
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="arrow-icon"
                          />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      {/* ============== */}
      <NewsLetter />
      <Footer />
    </>
  );
}
export default BlogPage;
